import React, { useState, useEffect, useRef } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Grid, Divider, Button, Typography, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { supplierDataAPI, supplierAPI } from '../../../../services';
import useDebounce from '../../../../hooks/useDebounce';
import { Supplier, SupplierLocationsInfo } from '../../../../types/suppliers';
import ChackedTable from '../CheckedTableComponent';
import ResultSearch from '../ResultSearchComponent';
import SupplierInfoBlock from '../SupplierInfoBlock';
import useLogOut from '../../../../hooks/useLogOut';

// CONST
const DURATION = 500;
const DEFAULT_LIMIT = 3000; // FIXED LIMIT

type SupplierLocationsProps = {
  supplier: Supplier;
  setStep?: any;
  refetch?: any;
};

const SupplierLocations: React.FC<SupplierLocationsProps> = ({ supplier }) => {
  // HOOKS STATES
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState({
    searchCounties: '',
    searchCities: ''
  });
  const [backButton, setBackButton] = useState(false);
  // UPDATE SUPPLIER
  const [updateSupplierLocation, { isSuccess, error: errorUpdate, isLoading }] =
    supplierAPI.useUpdateSupplierLocationMutation();

  // FUNCTIONS HANDLERS
  const handleUpdate = (data: SupplierLocationsInfo) => {
    updateSupplierLocation(data);
  };
  // FORMIK
  const formikRef = useRef();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    innerRef: formikRef,
    initialValues: {
      counties_ids: supplier.counties_ids,
      cities_ids: supplier.cities_ids
    },
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        cities_ids: values.cities_ids,
        id
      };
      handleUpdate(data);
      // resetForm();
      setSubmitting(false);
    }
  });
  const { handleSubmit, getFieldProps, setFieldValue, values } = formik;

  // DEBOUNCE HOOKS
  const debouncedValueCounties = useDebounce<string>(searchResult.searchCounties, DURATION);

  // FETCH COUNTIES & CITIES
  const {
    data: counties = { data: [], total: null },
    error: errorCounties,
    isLoading: loading
  } = supplierDataAPI.useFetchCountiesDataQuery({
    limit: DEFAULT_LIMIT,
    search: debouncedValueCounties
  });
  const {
    data: cities = { data: [], total: null },
    error: errorCities,
    isLoading: loadingItems
  } = supplierDataAPI.useFetchCitiesDataQuery({
    limit: DEFAULT_LIMIT,
    search: '',
    counties: JSON.stringify(values.counties_ids.length === 0 ? [null] : values.counties_ids)
  });
  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };
  // HOOKS
  useEffect(() => {
    if (errorCounties) errorHandler(errorCounties);
    if (errorCities) errorHandler(errorCities);
    if (errorUpdate) errorHandler(errorUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCounties, errorCities, errorUpdate]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });
    }
    if (isSuccess && !backButton) {
      navigate(`/supplier/${id}/edit-wastes`);
    }
    if (isSuccess && backButton) {
      navigate(`/supplier/${id}/edit`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, backButton]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  const checkedCitiesNames = cities?.data
    ?.filter((item) => values.cities_ids.includes(item?.id))
    .map(({ name }) => name.split(/(?=[A-Z])/)?.join(' '));

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Box sx={{ display: 'grid', rowGap: 5 }}>
          <SupplierInfoBlock {...supplier?.supplier} />
          <Divider />
          <Typography variant="h4" noWrap sx={{ flexGrow: 1 }} component="div">
            Collection Locations
          </Typography>
          <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, md: 12 }}>
            <Grid item xs={12} md={6} lg={4}>
              <ChackedTable
                setSearchResult={setSearchResult}
                searchResult={searchResult}
                title={'County'}
                searchKey="searchCounties"
                field="counties_ids"
                setFieldValue={setFieldValue}
                noRow="Collection Locations"
                loading={loading}
                childrenValue={values.cities_ids}
                childrenData={cities?.data}
                childrenField="cities_ids"
                {...counties}
                {...getFieldProps('counties_ids')}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ChackedTable
                setSearchResult={setSearchResult}
                searchResult={searchResult}
                title={'Cities & areas'}
                isDefaultChecked
                isFilterInsideKey="name"
                searchKey="searchCities"
                field="cities_ids"
                setFieldValue={setFieldValue}
                noRow="Collection Locations"
                loading={loadingItems}
                {...cities}
                {...getFieldProps('cities_ids')}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <ResultSearch title={'Locations'} data={checkedCitiesNames} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={!values.counties_ids.length || isLoading}
              type="button"
              variant="contained"
              onClick={() => {
                handleSubmit();
                setBackButton(true);
              }}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
            <Button
              disabled={isLoading || !values.counties_ids.length}
              startIcon={isLoading ? <CircularProgress size="0.9rem" /> : undefined}
              type="button"
              variant="contained"
              color="primary"
              value="save"
              onClick={() => handleSubmit()}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
export default SupplierLocations;
