import React from 'react';
import { Link } from 'react-router-dom';
import { PATH_PAGES, PATH_SUPPLIER } from '../../constants';
import { Container, Typography, Button, Box, Grid, Link as MuiLink } from '@mui/material';
import useAuth from '../../hooks/useAuth';

const SupplierMain: React.FC = () => {
  const { user } = useAuth();
  const isAdmin = !!user?.is_super_admin;
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        Welcome to Phoenix Waste Collection Admin Console
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        Please select one of the following...
      </Typography>
      <Box sx={{ mt: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={isAdmin ? 4 : 6}>
            <MuiLink component={Link} to={PATH_SUPPLIER.ADD_SUPPLIER} underline={'none'}>
              <Button fullWidth size="large" type="button" variant="contained">
                Add new supplier
              </Button>
            </MuiLink>
          </Grid>
          <Grid item xs={12} sm={isAdmin ? 4 : 6}>
            <MuiLink component={Link} to={PATH_SUPPLIER.LIST_SUPPLIERS} underline={'none'}>
              <Button fullWidth size="large" type="button" variant="contained">
                Edit existing supplier
              </Button>
            </MuiLink>
          </Grid>
          {isAdmin ? (
            <Grid item xs={12} sm={4}>
              <MuiLink component={Link} to={PATH_PAGES.USERS} underline={'none'}>
                <Button fullWidth size="large" type="button" variant="contained">
                  User management
                </Button>
              </MuiLink>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Container>
  );
};
export default SupplierMain;
