import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Button,
  TablePagination
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { tableCellClasses } from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import SupplierInfoBlock from '../SupplierInfoBlock';
import { Supplier } from '../../../../types/suppliers';
import { supplierDataAPI } from '../../../../services';
import { ContainerPutRequest } from '../../../../types/suppliersData';
import { Form, FormikProvider, useFormik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { array, object } from 'yup';
import { ContainerDto } from './container-dto';
import CustomNoRowsOverlay from '../../../NoData';
import { TableRowComponent } from './TableRowComponent';
import useLogOut from '../../../../hooks/useLogOut';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100
  }
}));

type CollectionPricesProps = {
  supplier: Supplier;
};
// CONST
const LIMIT = 10;
const STATUS_ACTIVE = 1;
const NO_VALUE = 0;

export const CollectionPrices: React.FC<CollectionPricesProps> = memo(({ supplier }) => {
  // USE HOOKS
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const [limit, setLimit] = useState(LIMIT);
  const [offset, setOffset] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // UPDATE MANY CONTAINERS
  const [
    updateSuppliersWasteTypeContainer,
    { error: errorUpdateContainers, isSuccess, isLoading }
  ] = supplierDataAPI.useUpdateSuppliersWasteTypeContainerManyMutation();
  // FETCH CONTAINERS
  const {
    data: supplierContainers = { data: [], count: 0 },
    error: errorSupplierContainers,
    isLoading: isLoadingContainers,
    isFetching: isFetchingContainers
  } = supplierDataAPI.useFetchSuppliersWasteTypeContainersQuery(
    {
      id,
      waste_type_id: JSON.stringify(supplier.waste_types_ids),
      limit,
      offset,
      statuses: JSON.stringify([STATUS_ACTIVE])
    },
    { skip: isLoading }
  );
  // FUNCTION HANDLERS
  const handleUpdate = (container: ContainerPutRequest) => {
    updateSuppliersWasteTypeContainer(container);
  };
  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  const NewPricesSchema = Yup.object().shape({
    containers: array(
      object({
        id: Yup.string(),
        price_per_collection: Yup.string(),
        free_of_charge: Yup.string(),
        pricing_details: Yup.string()
      })
    )
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      containers: supplierContainers?.data || []
    },
    validationSchema: NewPricesSchema,
    onSubmit: async () => {}
  });

  const { getFieldProps, values, setFieldValue } = formik;

  const newContainer = values.containers.map((el) => new ContainerDto(el));

  const handleChecked = useCallback(
    (e: any, index: number) => {
      setFieldValue(`containers[${index}].free_of_charge`, Number(e.target.checked));
      if (e.target.checked) {
        setFieldValue(`containers[${index}].price_per_collection`, 0);
      }
    },
    [setFieldValue]
  );
  const handleChangePrice = useCallback(
    (e: any, index: number) => {
      setFieldValue(`containers[${index}].price_per_collection`, Number(e.target.value));
      if (Number(e.target.value) > NO_VALUE) {
        setFieldValue(`containers[${index}].free_of_charge`, NO_VALUE);
      }
    },
    [setFieldValue]
  );

  const handleSubmitWithNavigate = () => {
    handleUpdate({ containers: newContainer });
    setSuccessSubmit(true);
  };
  // HOOKS
  useEffect(() => {
    if (errorSupplierContainers) errorHandler(errorSupplierContainers);
    if (errorUpdateContainers) errorHandler(errorUpdateContainers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSupplierContainers, errorUpdateContainers]);
  useEffect(() => {
    if (isSuccess && successSubmit) {
      navigate(`/supplier/list`);
    }
    // eslint-disable-next-line
  }, [isSuccess]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  //MEMO COMPONENTS
  const HeaderPrices = useMemo(() => {
    return (
      <>
        <SupplierInfoBlock {...supplier?.supplier} />
        <Divider />
        <Toolbar
          sx={{
            padding: '14px',
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h4" component="div">
            Containers Selected
          </Typography>
        </Toolbar>
      </>
    );
  }, [supplier]);
  const TableHeadMemo = useMemo(() => {
    return (
      <>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ m: 1, minWidth: 200 }}>Waste Type</StyledTableCell>
            <StyledTableCell sx={{ m: 1, minWidth: 200 }}>Collection Method</StyledTableCell>
            <StyledTableCell sx={{ m: 1, minWidth: 150 }}>Price per collection</StyledTableCell>
            <StyledTableCell sx={{ m: 1, minWidth: 100 }}>Free Collection</StyledTableCell>
            <StyledTableCell sx={{ m: 1, minWidth: 250 }}>Pricing details</StyledTableCell>
          </TableRow>
        </TableHead>
      </>
    );
  }, []);
  const tableRef: any = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (!isFetchingContainers && !isLoadingContainers) {
      tableRef?.current?.scrollTo(0, 0);
    }
  }, [isFetchingContainers, isLoadingContainers]);
  return (
    <Box>
      {HeaderPrices}
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off">
          <TableContainer
            sx={{ height: 600, maxWidth: 950, bgcolor: 'background.paper' }}
            component={Paper}
            ref={tableRef}
          >
            <Table sx={{ minWidth: '500px' }} stickyHeader aria-label="simple table">
              {TableHeadMemo}
              <TableBody>
                <FieldArray name="containers">
                  {() => (
                    <>
                      {supplierContainers?.data?.length ? (
                        values?.containers?.map((item, index: number) => (
                          <TableRowComponent
                            key={`${item?.id}---row`}
                            item={item}
                            index={index}
                            getFieldProps={getFieldProps}
                            handleChangePrice={handleChangePrice}
                            handleChecked={handleChecked}
                            id={id}
                          />
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <CustomNoRowsOverlay text="Containers Selected" />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </FieldArray>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ maxWidth: 950 }} />
          <TablePagination
            sx={{ bgcolor: 'background.paper', maxWidth: 950 }}
            labelRowsPerPage={matches ? 'Rows per page:' : ''}
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={supplierContainers?.count}
            rowsPerPage={limit}
            page={page}
            onPageChange={(event: unknown, newPage: number) => {
              setPage(newPage);
              setOffset(newPage * limit);
              handleUpdate({ containers: newContainer });
            }}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setOffset(0);
              setPage(0);
              handleUpdate({ containers: newContainer });
            }}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              variant="contained"
              onClick={handleSubmitWithNavigate}
              disabled={!supplierContainers?.data?.length}
            >
              Save supplier
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
});
