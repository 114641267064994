import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { spacing } from '@mui/system';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import {
  Container,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Box,
  Link as MuiLink,
  Hidden,
  Button,
  useTheme
} from '@mui/material';
import { PATH_SUPPLIER } from '../../constants';
import { SupplierList, SearchComponent } from '../../components/supplier/SupplierListComponents';
import { supplierAPI } from '../../services';
import useDebounce from '../../hooks/useDebounce';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Icon from '@mui/icons-material';
import useLogOut from '../../hooks/useLogOut';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const RootStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
// CONST
const LIMIT = 10;
const DURATION = 500;

export const SupplierListPage: React.FC = () => {
  // USE HOOKS
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(LIMIT);
  const [offset, setOffset] = useState(0);
  const debouncedValue = useDebounce<string>(search, DURATION);
  const { enqueueSnackbar } = useSnackbar();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  // FETCH LIST SUPPLIERS
  const { data: supplier = {}, error } = supplierAPI.useFetchAllSuppliersQuery({
    limit,
    offset,
    search: debouncedValue
  });
  // DELETE SUPPLIER
  const [deleteSupplier, { error: errorDelete }] = supplierAPI.useDeleteSupplierMutation();

  // FUNCTIONS HANDLERS
  const handleDelete = (id) => {
    deleteSupplier(id);
  };
  // FUNCTION HANDLER
  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };
  // HOOKS
  useEffect(() => {
    if (error) errorHandler(error);
    if (errorDelete) errorHandler(errorDelete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorDelete]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  return (
    <RootStyle>
      <Container sx={{ marginTop: 10, marginBottom: 10 }}>
        <Box sx={{ mb: 5 }}>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={8}>
              <Typography variant="h3" gutterBottom>
                List Suppliers
              </Typography>
              <Breadcrumbs>
                <MuiLink component={Link} to={PATH_SUPPLIER.ROOT}>
                  Phoenix Waste Collection
                </MuiLink>
                <Typography>List Suppliers</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={8}
              display="flex"
              flexDirection={matches ? 'column' : 'row'}
              justifyContent="flex-end"
              alignItems={matches ? 'flex-end' : 'center'}
            >
              <Hidden mdDown>
                <Typography padding={4} variant="h4">
                  Search Suppliers:
                </Typography>
              </Hidden>
              <SearchComponent setSearch={setSearch} />
              <MuiLink
                component={Link}
                to={PATH_SUPPLIER.ADD_SUPPLIER}
                underline={'none'}
                ml={4}
                mt={4}
                mb={4}
                width={'100%'}
                maxWidth={'140px'}
              >
                <Button type="button" size="small" variant="contained" color="primary">
                  <Icon.AddCircleOutline />
                  Add new supplier
                </Button>
              </MuiLink>
            </Grid>
          </Grid>
        </Box>
        <SupplierList
          limit={limit}
          setLimit={setLimit}
          setOffset={setOffset}
          handleDelete={handleDelete}
          {...supplier}
        />
      </Container>
    </RootStyle>
  );
};
