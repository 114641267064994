import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { spacing, SpacingProps } from '@mui/system';

import styled from 'styled-components/macro';
import { Button as MuiButton } from '@mui/material';

import useAuth from '../../hooks/useAuth';
interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>`
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  color: ${(props) => props.theme.header.color};
  border: none;
  background-color: transparent;
  ${spacing};
  &:hover {
    opacity: 0.8;
    border: none;
    background-color: transparent;
  }
`;

function NavbarUserDropdown() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate('/auth/sign-in');
  };

  return (
    <React.Fragment>
      <Button ml={2} color="primary" variant="contained" onClick={handleSignOut}>
        Sign out
      </Button>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
