import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Button, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: false
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string()
          .max(255)
          .required('Password is required')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least 8 characters, one uppercase, one lowercase letter, one number and one special case character'
          )
      })}
      validateOnChange
      onSubmit={(values) => {
        signIn(values.email, values.password);
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // disabled={isSubmitting}
          >
            Log in
          </Button>
          <Button component={Link} to="/auth/forgot-password" fullWidth color="primary">
            Forgot password
          </Button>
          <Button component={Link} to="/auth/sign-up" fullWidth color="primary">
            Create account
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default SignIn;
