import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CounterState {
  countiesChecked: any;
  citiesChecked: any;
  wasteTypesChecked: any;
  wasteItemsChecked: any;
  containerTypesChecked: any;
  containerSizesChecked: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CounterState = {
  countiesChecked: [null],
  citiesChecked: [],
  wasteTypesChecked: [null],
  wasteItemsChecked: [],
  containerTypesChecked: [null],
  containerSizesChecked: [],
  status: 'idle'
};

// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk('counter/fetchCount', async (amount: number) => {
  const response = await fetchCount(amount);
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const detailsSlice = createSlice({
  name: 'supplierDetails',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectCountiesAdd: (state, action: PayloadAction<any>) => {
      state.countiesChecked = [...state.countiesChecked, action.payload];
    },
    selectCountiesRemove: (state, action: PayloadAction<any>) => {
      state.countiesChecked = state.countiesChecked.filter((id: number) => id !== action.payload);
    },
    selectCitiesAdd: (state, action: PayloadAction<number>) => {
      state.citiesChecked = [...state.citiesChecked, action.payload];
    },
    selectCitiesRemove: (state, action: PayloadAction<number>) => {
      state.citiesChecked = state.citiesChecked.filter((id: number) => id !== action.payload);
    },
    selectWasteTypesAdd: (state, action: PayloadAction<number>) => {
      state.wasteTypesChecked = [...state.wasteTypesChecked, action.payload];
    },
    selectWasteTypesRemove: (state, action: PayloadAction<number>) => {
      state.wasteTypesChecked = state.wasteTypesChecked.filter(
        (id: number) => id !== action.payload
      );
    },
    selectWasteItemsAdd: (state, action: PayloadAction<number>) => {
      state.wasteItemsChecked = [...state.wasteItemsChecked, action.payload];
    },
    selectWasteItemsRemove: (state, action: PayloadAction<number>) => {
      state.wasteItemsChecked = state.wasteItemsChecked.filter(
        (id: number) => id !== action.payload
      );
    },
    selectContainerTypesAdd: (state, action: PayloadAction<number>) => {
      state.containerTypesChecked = [...state.containerTypesChecked, action.payload];
    },
    selectContainerTypesRemove: (state, action: PayloadAction<number>) => {
      state.containerTypesChecked = state.containerTypesChecked.filter(
        (id: number) => id !== action.payload
      );
    },
    selectContainerSizesAdd: (state, action: PayloadAction<number>) => {
      state.containerSizesChecked = [...state.containerSizesChecked, action.payload];
    },
    selectContainerSizesRemove: (state, action: PayloadAction<number>) => {
      state.containerSizesChecked = state.containerSizesChecked.filter(
        (id: number) => id !== action.payload
      );
    },
    clearSelectData: (state) => initialState
  }
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(incrementAsync.pending, (state) => {
  //         state.status = 'loading';
  //       })
  //       .addCase(incrementAsync.fulfilled, (state, action) => {
  //         state.status = 'idle';
  //         // state.value += action.payload;
  //       });
  //   }
});

export const {
  selectCountiesAdd,
  selectCountiesRemove,
  selectCitiesAdd,
  selectCitiesRemove,
  selectWasteTypesAdd,
  selectWasteTypesRemove,
  selectWasteItemsAdd,
  selectWasteItemsRemove,
  selectContainerSizesAdd,
  selectContainerSizesRemove,
  selectContainerTypesAdd,
  selectContainerTypesRemove,
  clearSelectData
} = detailsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export default detailsSlice.reducer;
