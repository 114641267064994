import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const ConfirmEmail: React.FC = () => {
  const { confirmEmail } = useAuth();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  useEffect(() => {
    if (token) confirmEmail(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default ConfirmEmail;
