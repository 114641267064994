import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { JWTContextType, ActionMap, AuthState, AuthUser, IAuthSignUpBody } from '../types/auth';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';
const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
const VERIFIED = 'VERIFIED';
const CONFIRM_VERIFIED = 'CONFIRM_VERIFIED';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RESET_PASSWORD = 'RESET_PASSWORD';

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    status: boolean;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    status: boolean;
  };
  [CONFIRM_EMAIL]: {
    status: boolean;
  };
  [FORGOT_PASSWORD]: {
    status: boolean;
  };
  [RESET_PASSWORD]: {
    status: boolean;
  };
  [VERIFIED]: {
    status: boolean;
  };
  [CONFIRM_VERIFIED]: {
    user: AuthUser;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case SIGN_IN:
      return {
        ...state,
        status: action.payload.status
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    case SIGN_UP:
      return {
        ...state,
        status: action.payload.status
      };
    case CONFIRM_EMAIL:
      return {
        ...state,
        status: action.payload.status
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        status: action.payload.status
      };
    case RESET_PASSWORD:
      return {
        ...state,
        status: action.payload.status
      };
    case VERIFIED:
      return {
        ...state,
        status: action.payload.status
      };
    case CONFIRM_VERIFIED:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const errorHandler = (error: any) => {
    let message: string = 'Something went wrong!';
    if (error?.status === 401) {
      message = 'You are not authorized!';
      setSession(null);
      dispatch({ type: SIGN_OUT });
    }
    if (error?.errors) {
      let errorMessage: any | string = Object.values(error?.errors)
        .map((item: any) => item?.message || item)
        .join('');
      switch (true) {
        case errorMessage === 'Invalid credentials':
          message = 'The provided email or password is incorrect!';
          break;
        case errorMessage === 'number':
          message = 'The provided password must contain at least 1 digit!';
          break;
        case errorMessage === 'lowercase':
          message = 'The provided password must contain at least 1 lowercase latter!';
          break;
        case errorMessage === 'uppercase':
          message = 'The provided password must contain at least 1 uppercase latter!';
          break;
        case errorMessage === 'special':
          message = 'The provided password must contain at least 1 special symbol!';
          break;
        default:
          message = errorMessage;
          break;
      }
    }

    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  const statusMessageHandler = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success'
    });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get('/auth/current');
          const { user } = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          // setSession(null);
        }
      } catch (err: any) {
        console.error(err);
        errorHandler(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        // setSession(null);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await axios.post('/auth/login', {
        email,
        password
      });
      const { status, redirect_to, notification } = response.data;
      if (status && redirect_to) {
        dispatch({
          type: SIGN_IN,
          payload: {
            status
          }
        });
        // window.location.href = redirect_to;
        const url = redirect_to.split('/auth');
        navigate(`/auth${url[1]}`);
        if (notification) {
          enqueueSnackbar(notification, {
            variant: 'success'
          });
        }
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (body: IAuthSignUpBody) => {
    try {
      const response = await axios.post('/auth/register', body);
      const { status, message } = response.data;
      if (status) {
        dispatch({
          type: SIGN_UP,
          payload: {
            status
          }
        });
        statusMessageHandler(message);
      }
    } catch (err) {
      console.log('signUp', err);
      errorHandler(err);
    }
  };
  const confirmEmail = async (token: string) => {
    try {
      const response = await axios.post('/auth/confirm-email', {
        token
      });
      const { status, redirect_to } = response.data;
      if (status && redirect_to) {
        dispatch({
          type: CONFIRM_EMAIL,
          payload: {
            status
          }
        });
        window.location.href = redirect_to;
      }
    } catch (err) {
      console.log('resetPassword', err);
      errorHandler(err);
    }
  };
  const resetPassword = async (password: string, token: string) => {
    try {
      const response = await axios.post('/auth/recovery-password', {
        password,
        token
      });
      const { status } = response.data;
      if (status) {
        dispatch({
          type: RESET_PASSWORD,
          payload: {
            status
          }
        });
        navigate('/auth/sign-in');
      }
    } catch (err) {
      console.log('resetPassword', err);
      errorHandler(err);
    }
  };
  const forgotPassword = async (email: string) => {
    try {
      const response = await axios.post('/auth/forgot-password', {
        email
      });
      const { status, message } = response.data;
      if (status) {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: {
            status
          }
        });
        statusMessageHandler(message);
      }
    } catch (err) {
      console.log('forgotPassword', err);
      errorHandler(err);
    }
  };
  const verified = async (
    email?: string,
    phone?: string,
    token?: string,
    handleNext?: () => void
  ) => {
    try {
      const response = await axios.post('/auth/2fa-code', {
        email,
        phone,
        token
      });
      const { status } = response.data;
      if (status) {
        dispatch({
          type: VERIFIED,
          payload: {
            status
          }
        });
        if (typeof handleNext !== 'undefined') {
          handleNext();
        }
      }
    } catch (err: any) {
      console.log('verified', err);
      if (err?.message) {
        enqueueSnackbar(err?.message, {
          variant: 'error'
        });
      } else {
        errorHandler(err);
      }
    }
  };
  const confirmVerified = async (code: string, token: string) => {
    try {
      const response = await axios.post('/auth/2fa-confirm', {
        code,
        token
      });
      const { token: accessToken, user } = response.data;

      window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: CONFIRM_VERIFIED,
        payload: {
          user
        }
      });
    } catch (err) {
      console.log('confirm-verified', err);
      enqueueSnackbar('The code was entered incorrectly', {
        variant: 'error'
      });
      // errorHandler(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signOut,
        signUp,
        confirmEmail,
        forgotPassword,
        resetPassword,
        verified,
        confirmVerified
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
