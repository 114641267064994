import React, { useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation, useParams } from 'react-router-dom';
import '../../vendor/perfect-scrollbar.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar};
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarNav: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const { id }: any = useParams();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const ScrollbarComponent = (matches ? PerfectScrollbar : Scrollbar) as React.ElementType;
  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const pathname = location.pathname;

  const steps = [
    {
      label: 'Add Supplier Details',
      url: ``
    },
    {
      label: 'Specify Collection Methods',
      url: `/supplier/${id}/edit`
    },
    {
      label: 'Add Collection Prices',
      url: `/supplier/${id}/methods`
    }
  ];
  useEffect(() => {
    if (pathname.includes('methods')) {
      setActiveStep(1);
    } else if (pathname.includes('prices')) {
      setActiveStep(2);
    } else {
      setActiveStep(0);
    }
  }, [pathname]);

  return (
    <ScrollbarComponent>
      <Container sx={{ marginTop: 10, marginBottom: 10 }}>
        <Box sx={{ maxWidth: 400 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>

                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        component={Link}
                        to={step.url}
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        variant="outlined"
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Container>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
