import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { spacing } from '@mui/system';
import { useSnackbar } from 'notistack';
import { Breadcrumbs as MuiBreadcrumbs, Typography, Box, Link as MuiLink } from '@mui/material';
import { PATH_SUPPLIER } from '../../constants';
import { supplierAPI } from '../../services';
import useLogOut from '../../hooks/useLogOut';
import { CollectionPrices } from '../../components/supplier/SupplierFormComponents/CollectionPrices';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const SupplierFormPage: React.FC = () => {
  // USE HOOKS
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  // FETCH SUPPLIER
  const { data: supplierData = {}, error } = supplierAPI.useFetchSupplierWastesQuery({ id });
  // HANDLERS
  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };
  // HOOKS
  useEffect(() => {
    if (error) errorHandler(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  return (
    <React.Fragment>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Add Collection Prices
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={PATH_SUPPLIER.ROOT}>
            Phoenix Waste Collection
          </MuiLink>
          <Typography>Add Collection Prices</Typography>
        </Breadcrumbs>
      </Box>
      <CollectionPrices
        supplier={{
          waste_types_ids: [],
          ...supplierData
        }}
      />
    </React.Fragment>
  );
};

export default SupplierFormPage;
