import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  CircularProgress as MuiCircularProgress,
  TextField
} from '@mui/material';
import { spacing } from '@mui/system';
import { tableCellClasses } from '@mui/material/TableCell';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import SupplierInfoBlock from './SupplierInfoBlock';
import { Supplier, SupplierCollectionInfo, RecyclingData } from '../../../types/suppliers';
import { ContainerPutRequest, ContainerSelectAllRequest } from '../../../types/suppliersData';
import { supplierDataAPI } from '../../../services';
import Loader from '../../Loader';
import CustomNoRowsOverlay from '../../NoData';
import * as Yup from 'yup';
import { array, object } from 'yup';
import { Form, FormikProvider, useFormik, FieldArray } from 'formik';
import PercentageRateField from './SupplierDetails/PercentageRateField';
import useLogOut from '../../../hooks/useLogOut';

// CONST
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const STATUS_ACTIVE = 1;
const STATUS_INACTIVE = 0;
const PERCENTAGE = 100;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const MenuProps = {
  PaperProps: {
    style: {
      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px'
    }
  }
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100
  }
}));

type SpecifyCollectionMethodsProps = {
  supplier: Supplier;
};
const RegExp = /^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
const PercentageValidation = (val: string | undefined) => Number(val) <= 1;

const SpecifyCollectionMethods: React.FC<SpecifyCollectionMethodsProps> = ({ supplier }) => {
  // USE HOOKS
  const { enqueueSnackbar } = useSnackbar();
  const [activeSelect, setActiveSelect] = useState<boolean>(false);
  const [currentWasteTypeId, setCurrentWasteTypeId] = useState([]);
  const [currentContainer, setCurrentContainer] = useState<number | null>(null);
  const [defaultPercentage, setDefaultPercentage] = useState<number>(0);
  const [defaultSet, setDefaultSet] = useState(false);
  const { id } = useParams();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const navigate = useNavigate();
  const [indeterminate, setIndeterminate] = useState(false);
  // FETCH WASTE TYPES
  const { data: wasteTypes = { data: [], total: null, hasMore: '' }, error: errorWasteTypes } =
    supplierDataAPI.useFetchWasteTypesDataQuery({
      limit: 15,
      search: ''
    });
  // FETCH SUPPLIER CONTAINERS
  const {
    data: supplierContainers = { data: [] },
    error: errorSupplierContainers,
    isFetching
  } = supplierDataAPI.useFetchSuppliersWasteTypeContainersQuery(
    {
      id,
      waste_type_id: JSON.stringify(currentWasteTypeId),
      limit: 100,
      statuses: JSON.stringify([STATUS_ACTIVE, STATUS_INACTIVE])
    },
    { skip: !activeSelect }
  );
  // UPDATE CONTAINERS
  const [updateSuppliersWasteTypeContainer, { error: errorUpdateContainers }] =
    supplierDataAPI.useUpdateSuppliersWasteTypeContainerMutation();

  const [updateSelectAllWasteTypeContainer, { error: errorUpdateSelectAllContainers }] =
    supplierDataAPI.useUpdateSelectAllWasteTypeContainerMutation();

  const wasteTypesArray = wasteTypes?.data?.filter((item) =>
    supplier.waste_types_ids.includes(item?.id)
  );
  const wasteTypesArrayId = wasteTypesArray.map((el) => el.id);
  // RECYCLING QUERY
  const {
    data: supplierContainersRecycled = { data: [] },
    error: errorSupplierContainersRecycled,
    isFetching: isFetchingRecycled
  } = supplierDataAPI.useFetchSuppliersWasteTypeContainersRecycledQuery(
    {
      id,
      waste_type_id: JSON.stringify(wasteTypesArrayId),
      limit: 1000,
      statuses: JSON.stringify([STATUS_ACTIVE, STATUS_INACTIVE])
    },
    { skip: !wasteTypesArrayId }
  );
  // UPDATE RECYCLING
  const [updateSuppliersRecycling, { error: errorUpdateSuppliersRecycling }] =
    supplierDataAPI.useUpdateSuppliersRecyclingMutation();

  const handleUpdateRecycling = (supplierId: SupplierCollectionInfo, data: RecyclingData) => {
    updateSuppliersRecycling({ supplier: supplierId.supplier.id, data: data });
  };
  // FORMIC
  const NewMethodsSchema = Yup.object().shape({
    default_recycling_rate: Yup.string().matches(RegExp, 'Is not valid').test({
      test: PercentageValidation,
      message: 'Should not exceed 100'
    }),
    supplier_waste_types_recycling: array(
      object({
        recycling_rate: Yup.string().matches(RegExp, 'Is not valid').test({
          test: PercentageValidation,
          message: 'Should not exceed 100'
        })
      })
    )
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      default_recycling_rate: 0,
      supplier_waste_types_recycling: supplierContainersRecycled?.recyclingRate || []
    },
    validationSchema: NewMethodsSchema,
    onSubmit: async () => {}
  });
  const { getFieldProps, values, setFieldValue, errors, touched, isValid } = formik;

  const RecyclingValData: any =
    values.supplier_waste_types_recycling.length > 0 &&
    values.supplier_waste_types_recycling.filter((el: any) => !!el && el);

  // FUNCTION HANDLER
  const handleUpdate = (container: ContainerPutRequest) => {
    updateSuppliersWasteTypeContainer(container);
  };
  const handleSelectAll = (container: ContainerSelectAllRequest) => {
    updateSelectAllWasteTypeContainer(container);
  };
  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };
  // HOOKS
  useEffect(() => {
    if (errorWasteTypes) errorHandler(errorWasteTypes);
    if (errorSupplierContainers) errorHandler(errorSupplierContainers);
    if (errorUpdateContainers) errorHandler(errorUpdateContainers);
    if (errorUpdateSelectAllContainers) errorHandler(errorUpdateSelectAllContainers);
    if (errorUpdateSuppliersRecycling) errorHandler(errorUpdateSuppliersRecycling);
    if (errorSupplierContainersRecycled) errorHandler(errorSupplierContainersRecycled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errorWasteTypes,
    errorSupplierContainers,
    errorUpdateContainers,
    errorUpdateSelectAllContainers,
    errorUpdateSuppliersRecycling,
    errorSupplierContainersRecycled
  ]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  const handleOpen = (e, id) => {
    setCurrentWasteTypeId([id]);
    setActiveSelect(true);
  };
  useEffect(() => {
    if (supplierContainers) {
      const arr = supplierContainers?.data?.map(({ status }) => status);
      const size = new Set(arr).size;
      if (size !== 1) {
        setIndeterminate(true);
      } else {
        setIndeterminate(false);
      }
    }
  }, [supplierContainers]);

  const handleSetPercentage = useCallback(() => {
    setDefaultSet(true);
    wasteTypesArray.map((el, index) =>
      setFieldValue(
        `supplier_waste_types_recycling[${index}].recycling_rate`,
        defaultPercentage / PERCENTAGE
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasteTypesArray]);

  const handleChangePercentage = useCallback(
    (e: any) => {
      setDefaultPercentage(e.target.value);
      setFieldValue(`default_recycling_rate`, Number(e.target.value) / PERCENTAGE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );
  return (
    <Box>
      <SupplierInfoBlock {...supplier?.supplier} />
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Toolbar
            sx={{
              padding: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <Typography variant="h4" component="div">
              How is the waste collected?
            </Typography>
            <Box>
              <Typography variant="h4" component="div" padding={'12px 0 12px 0'}>
                Recycling Rate
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <TextField
                  {...getFieldProps('default_recycling_rate')}
                  placeholder="Default percentage"
                  variant="outlined"
                  disabled={isFetchingRecycled}
                  type="number"
                  sx={{ mb: 3 }}
                  inputProps={{
                    min: '0',
                    max: '100'
                  }}
                  value={defaultPercentage}
                  onChange={handleChangePercentage}
                  fullWidth
                  error={Boolean(touched?.default_recycling_rate && errors?.default_recycling_rate)}
                  helperText={touched?.default_recycling_rate && errors?.default_recycling_rate}
                />
                <Button
                  variant="outlined"
                  disabled={
                    Boolean(touched?.default_recycling_rate && errors?.default_recycling_rate) ||
                    isFetchingRecycled
                  }
                  onClick={handleSetPercentage}
                >
                  Set for all
                </Button>
              </Box>
            </Box>
          </Toolbar>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off">
              <TableContainer sx={{ height: 600, bgcolor: 'background.paper' }} component={Paper}>
                <Table sx={{ minWidth: '420px' }} stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ m: 1, width: 200 }}>
                        Waste Type Collected
                      </StyledTableCell>
                      <StyledTableCell sx={{ m: 1, width: 200 }}>Collection Method</StyledTableCell>
                      <StyledTableCell sx={{ m: 1, width: 150 }}>Recycling Rate</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="containers">
                      {() => (
                        <>
                          {wasteTypesArray?.length ? (
                            wasteTypesArray?.map(({ name, id: waste_type_id }, index: number) => (
                              <TableRow
                                key={`${waste_type_id}-${name}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" id={`${waste_type_id}`}>
                                  {name}
                                </TableCell>
                                <TableCell>
                                  <FormControl sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-multiple-checkbox-label">
                                      Method
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      value={[]}
                                      onOpen={(e) => handleOpen(e, waste_type_id)}
                                      label="Method"
                                      renderValue={(selected) => selected.join(', ')}
                                      MenuProps={MenuProps}
                                    >
                                      <div>
                                        <MenuItem value="Select All">
                                          <FormGroup sx={{ width: '100%' }}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={
                                                    !!supplierContainers?.data?.every(
                                                      ({ status }) => status === 1
                                                    )
                                                  }
                                                  onChange={(e) => {
                                                    const container = {
                                                      status: Number(e.target.checked),
                                                      id,
                                                      waste_type_id
                                                    };
                                                    handleSelectAll(container);
                                                  }}
                                                  indeterminate={indeterminate}
                                                />
                                              }
                                              label="Select All"
                                            />
                                          </FormGroup>
                                        </MenuItem>
                                        {supplierContainers?.data?.map(
                                          ({ id, status, collection_info }, index) => (
                                            <MenuItem
                                              key={`${id}-menu_item`}
                                              value={collection_info?.container_name}
                                            >
                                              <FormGroup sx={{ width: '100%' }}>
                                                <FormControlLabel
                                                  control={
                                                    <>
                                                      {isFetching && index === currentContainer ? (
                                                        <Loader />
                                                      ) : (
                                                        <Checkbox
                                                          disabled={isFetching}
                                                          checked={!!status}
                                                          onChange={(e) => {
                                                            const container = {
                                                              status: Number(e.target.checked),
                                                              id
                                                            };
                                                            handleUpdate(container);
                                                            setCurrentContainer(index);
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  }
                                                  label={collection_info?.container_name}
                                                />
                                              </FormGroup>
                                            </MenuItem>
                                          )
                                        )}
                                      </div>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell component="th">
                                  <PercentageRateField
                                    recyclingRate={supplierContainersRecycled?.recyclingRate}
                                    id={waste_type_id}
                                    defaultSet={defaultSet}
                                    defaultPercentage={defaultPercentage}
                                    setDefaultSet={setDefaultSet}
                                    index={index}
                                    getFieldProps={getFieldProps}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    isFetchingRecycled={isFetchingRecycled}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                {isFetchingRecycled ? (
                                  <CircularProgress m={2} />
                                ) : (
                                  <CustomNoRowsOverlay text="Collection Methods" />
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </TableBody>
                </Table>
              </TableContainer>
            </Form>
          </FormikProvider>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!wasteTypesArray?.length || !isValid || isFetchingRecycled}
          type="submit"
          variant="contained"
          onClick={() => {
            if (isValid) {
              handleUpdateRecycling(supplier, RecyclingValData);
              navigate(`/supplier/${id}/prices`);
            }
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
export default SpecifyCollectionMethods;
