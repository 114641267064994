import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CountiesListData,
  CitiesListData,
  WasteTypesData,
  WasteItemsData,
  ContainerTypesData,
  ContainerSizesData,
  ZipCodesData,
  CollectionMethodContainerData,
  ContainerPutRequest,
  ContainerSelectAllRequest,
  Recycling
} from '../types/suppliersData';

export const supplierDataAPI = createApi({
  reducerPath: 'supplierDataAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    'Counties',
    'Cities',
    'WasteTypes',
    'WasteItems',
    'ContainerTypes',
    'ContainerSizes',
    'ZipCodes',
    'SuppliersWasteTypeContainers',
    'SuppliersWasteTypeContainersMany',
    'SuppliersWasteTypeRecycling'
  ],
  endpoints: (build) => ({
    fetchCountiesData: build.query<CountiesListData, { limit: number; search: string }>({
      query: ({ limit, search }) => ({
        url: `/location/county`,
        params: {
          limit: limit,
          search: search
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['Counties']
    }),
    fetchCitiesData: build.query<CitiesListData, { limit: number; search: string; counties: any }>({
      query: ({ limit, search, counties }) => ({
        url: `/location/city`,
        params: {
          limit: limit,
          search: search,
          counties: counties
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['Cities']
    }),
    fetchWasteTypesData: build.query<
      WasteTypesData,
      { limit: number; search: string; offset?: number }
    >({
      query: ({ limit, search, offset }) => ({
        url: `/waste-types`,
        params: {
          limit: limit,
          search: search,
          offset: offset
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['WasteTypes']
    }),
    fetchWasteItemsData: build.query<
      WasteItemsData,
      { limit: number; search: string; waste_types: any }
    >({
      query: ({ limit, search, waste_types }) => ({
        url: `/waste-items`,
        params: {
          limit: limit,
          search: search,
          waste_types: waste_types
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['WasteItems']
    }),
    fetchContainerTypesData: build.query<ContainerTypesData, { limit: number; search: string }>({
      query: ({ limit, search }) => ({
        url: `/containers-types`,
        params: {
          limit: limit,
          search: search
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['ContainerTypes']
    }),
    fetchContainerSizesData: build.query<
      ContainerSizesData,
      { limit: number; search: string; containers_types: any }
    >({
      query: ({ limit, search, containers_types }) => ({
        url: `/containers`,
        params: {
          limit: limit,
          search: search,
          containers_types: containers_types
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['ContainerSizes']
    }),
    fetchZipCodesData: build.query<ZipCodesData, { limit: number; search: string; cities: any }>({
      query: ({ limit, search, cities }) => ({
        url: `/location/code`,
        params: {
          limit: limit,
          search: search,
          cities: cities
        }
      }),
      // keepUnusedDataFor: 0,
      providesTags: () => ['ZipCodes']
    }),
    fetchSuppliersWasteTypeContainers: build.query<
      CollectionMethodContainerData,
      {
        id?: string | undefined;
        waste_type_id: any;
        limit?: number | undefined;
        offset?: number | undefined | null;
        statuses: any;
      }
    >({
      query: ({ id, waste_type_id, limit, offset, statuses }) => ({
        url: `/suppliers/${id}/containers`,
        params: {
          waste_type_id: waste_type_id,
          limit: limit,
          offset: offset,
          statuses: statuses
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['SuppliersWasteTypeContainers']
    }),
    fetchSuppliersWasteTypeContainersRecycled: build.query<
      CollectionMethodContainerData,
      { id: string; waste_type_id: any; limit?: number; offset?: number; statuses: any }
    >({
      query: ({ id, waste_type_id, limit, offset, statuses }) => ({
        url: `/suppliers/${id}/containers`,
        params: {
          waste_type_id: waste_type_id,
          limit: limit,
          offset: offset,
          statuses: statuses,
          recycling_rate: true
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['SuppliersWasteTypeRecycling']
    }),
    updateSuppliersWasteTypeContainer: build.mutation<ContainerPutRequest, ContainerPutRequest>({
      query: (container) => ({
        url: `/suppliers/containers/${container.id}`,
        method: 'PUT',
        body: {
          status: container.status,
          price_per_collection: container.price_per_collection,
          free_of_charge: container.free_of_charge
        }
      }),
      invalidatesTags: ['SuppliersWasteTypeContainers']
    }),
    updateSuppliersWasteTypeContainerMany: build.mutation<ContainerPutRequest, ContainerPutRequest>(
      {
        query: (body) => ({
          url: `/suppliers/containers/updateMany`,
          method: 'PUT',
          body
        }),
        invalidatesTags: ['SuppliersWasteTypeContainers']
      }
    ),
    updateSelectAllWasteTypeContainer: build.mutation<
      ContainerSelectAllRequest,
      ContainerSelectAllRequest
    >({
      query: (container) => ({
        url: `/suppliers/${container.id}/containers/all`,
        method: 'PUT',
        body: {
          status: container.status,
          waste_type: container.waste_type_id
        }
      }),
      invalidatesTags: ['SuppliersWasteTypeContainers']
    }),
    updateSuppliersRecycling: build.mutation<Recycling, { supplier: any; data: any }>({
      query: ({ supplier, data }) => ({
        url: `/suppliers/${supplier}/recycling`,
        method: 'PUT',
        body: {
          supplier_waste_types_recycling: data
        }
      }),
      invalidatesTags: ['SuppliersWasteTypeRecycling']
    })
  })
});
