import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

// CONST
const PERCENTAGE = 100;

type Props = {
  getFieldProps: any;
  id: number;
  setFieldValue: any;
  errors: any;
  touched: any;
  recyclingRate: any;
  index: number;
  isFetchingRecycled: boolean;
  defaultSet: boolean;
  defaultPercentage: number | null;
  setDefaultSet: (b: boolean) => void;
};

const useHelperTextStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: '-20px',
    width: '122px',
    marginLeft: '-15px',
    marginRight: 0
  }
}));

const PercentageRateField: React.FC<Props> = ({
  setFieldValue,
  id,
  getFieldProps,
  errors,
  touched,
  recyclingRate = [],
  index,
  isFetchingRecycled,
  defaultSet,
  defaultPercentage,
  setDefaultSet
}) => {
  // USE HOOKS
  const [valueRecyclingRate, setValueRecyclingRate] = useState<number | null>(null);
  const helperTextStyles = useHelperTextStyles();
  const handleChangePercentage = useCallback(
    (e: any) => {
      setValueRecyclingRate(e.target.value);
      setFieldValue(
        `supplier_waste_types_recycling[${index}].recycling_rate`,
        Number(e.target.value) / PERCENTAGE
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );
  const checkRecyclingId = useMemo(() => {
    const checkKeys = recyclingRate?.filter((el: any) => el.waste_type_id === id);
    return checkKeys[0]?.recycling_rate;
  }, [id, recyclingRate]);
  useEffect(() => {
    if (defaultSet) {
      setValueRecyclingRate(defaultPercentage);
      setDefaultSet(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSet]);
  return (
    <TextField
      {...getFieldProps(`supplier_waste_types_recycling[${index}].recycling_rate`)}
      placeholder="Percentage"
      variant="outlined"
      disabled={isFetchingRecycled}
      type="number"
      inputProps={{
        min: '0',
        max: '100'
      }}
      value={valueRecyclingRate ?? Math.round(checkRecyclingId * PERCENTAGE)}
      onChange={handleChangePercentage}
      FormHelperTextProps={{
        classes: {
          root: helperTextStyles.root
        }
      }}
      fullWidth
      error={Boolean(
        touched?.supplier_waste_types_recycling &&
          errors?.supplier_waste_types_recycling &&
          touched?.supplier_waste_types_recycling[index]?.recycling_rate &&
          errors?.supplier_waste_types_recycling[index]?.recycling_rate
      )}
      helperText={
        touched?.supplier_waste_types_recycling &&
        errors?.supplier_waste_types_recycling &&
        touched?.supplier_waste_types_recycling[index]?.recycling_rate &&
        errors?.supplier_waste_types_recycling[index]?.recycling_rate
      }
    />
  );
};
export default PercentageRateField;
