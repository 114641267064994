import React from 'react';
import styled from 'styled-components/macro';
import { Paper, Typography, Box } from '@mui/material';

import ConfirmVerifiedComponent from '../..//components/auth/ConfirmVerified';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export const Code: React.FC = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h3" paragraph>
          Please enter the authentication code!
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          We sent a 6-digit confirmation code, please enter the code in the box below to verify your
          account.
        </Typography>
        <Box sx={{ mt: 5, mb: 3 }}>
          <ConfirmVerifiedComponent />
        </Box>
      </Wrapper>
    </React.Fragment>
  );
};
