import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Button, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const ResetPassword: React.FC = () => {
  const { search } = useLocation();
  const { resetPassword } = useAuth();
  const token = new URLSearchParams(search).get('token');

  return (
    <Formik
      initialValues={{
        password: '',
        submit: false
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().min(8, 'Must be at least 8 characters').max(255).required('Required')
      })}
      onSubmit={(values) => {
        resetPassword(values.password, token);
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // disabled={isSubmitting}
          >
            Reset password
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default ResetPassword;
