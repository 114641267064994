import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

function useLogOut(status?: number | null) {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate('/auth/sign-in');
  };
  if (status === 401) {
    return { logOut: handleSignOut };
  }
  return { logOut: null };
}

export default useLogOut;
