import React, { useEffect, useRef, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import useDebounce from '../../../../hooks/useDebounce';
import { supplierDataAPI } from '../../../../services';
import { FieldInputProps } from 'formik';
import useObserver from '../../../../hooks/useObserver';

const DURATION = 500;
const DEFAULT_LIMIT = 30; // FIXED LIMIT

type SupplierZipCodeAutocompleteProps = {
  isSubmitting?: any;
  touched: any;
  errors: any;
  setFieldValue: any;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  handleBlur: {
    (e: FocusEvent): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  index: number;
  values: any;
  errorHandler: (error: any) => void;
};

const SupplierZipCodeAutocomplete: React.FC<SupplierZipCodeAutocompleteProps> = ({
  isSubmitting,
  touched,
  errors,
  setFieldValue,
  handleBlur,
  getFieldProps,
  index,
  values,
  errorHandler
}) => {
  const [searchResult, setSearchResult] = useState('');
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);
  const ref: any = useRef<HTMLUListElement>(null);
  // DEBOUNCE HOOKS
  const debouncedZipCodes = useDebounce<string>(searchResult, DURATION);
  // FETCH ZIP CODES
  const { data: zipCodes = { data: [], total: null }, error: errorZipCodes } =
    supplierDataAPI.useFetchZipCodesDataQuery(
      { limit: limit, search: debouncedZipCodes, cities: [] },
      { refetchOnMountOrArgChange: 1000 }
    );

  const { pageNum, setLastElement } = useObserver();

  useEffect(() => {
    if (pageNum > 1) {
      setLimit(limit + 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  useEffect(() => {
    if (errorZipCodes) errorHandler(errorZipCodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorZipCodes]);
  return (
    <Autocomplete
      id="zip-code-autocomplete"
      sx={{ mt: 3 }}
      disabled={isSubmitting}
      ListboxProps={{ ref }}
      options={zipCodes?.data || []}
      autoHighlight
      getOptionLabel={(option) => `${option?.code}` || ''}
      onInputChange={(e: any, value: any) => {
        setSearchResult(value);
      }}
      onChange={(e: any, value: any) => {
        setFieldValue(`addresses[${index}].zip_code`, value?.code ?? '');
      }}
      value={{ code: values.addresses[index].zip_code ?? '' }}
      onOpen={handleBlur}
      includeInputInList
      isOptionEqualToValue={(option, value) => option.code === value.code}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id} ref={setLastElement}>
            {option.code}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...getFieldProps(`addresses[${index}].zip_code`)}
          error={Boolean(
            touched?.addresses &&
              errors?.addresses &&
              touched?.addresses[index]?.zip_code &&
              errors?.addresses[index]?.zip_code
          )}
          helperText={
            touched?.addresses &&
            errors?.addresses &&
            touched?.addresses[index]?.zip_code &&
            errors?.addresses[index]?.zip_code
          }
          label="Zip Code"
          // name="zip_code"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
export default SupplierZipCodeAutocomplete;
