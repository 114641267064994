import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Stack
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { tableCellClasses } from '@mui/material/TableCell';
import { Supplier } from '../../../types/suppliers';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomNoRowsOverlay from '../../NoData';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100
  }
}));

interface SupplierComponentProps {
  data?: Supplier[];
  count?: any;
  limit: number;
  setLimit: any;
  setOffset: any;
  handleDelete: any;
}
export const SupplierList: React.FC<SupplierComponentProps> = ({
  data = [],
  count,
  limit,
  setLimit,
  setOffset,
  handleDelete
}) => {
  const navigate = useNavigate();
  const getSupplierHandler = (id: any) => {
    return navigate(`/supplier/${id}/edit`);
  };

  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = React.useState(null);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDeleteSupplier = (id: any) => {
    handleDelete({ id });
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (id: any) => {
    setItemId(id);
    setOpen(true);
  };

  return (
    <div style={{ width: '100%' }}>
      <TableContainer sx={{ height: 650, bgcolor: 'background.paper' }} component={Paper}>
        <Table stickyHeader aria-label="simple table" sx={{ minWidth: '850px' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>City</StyledTableCell>
              <StyledTableCell>Street</StyledTableCell>
              <StyledTableCell>Mobile Number</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Email Address</StyledTableCell>
              <StyledTableCell>Supplier Num.</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data?.map((item: any) => (
                <TableRow
                  key={`${item?.id}---`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ m: 1, width: 200 }} component="th">
                    {item?.company_name}
                  </TableCell>
                  <TableCell sx={{ m: 1, width: 200 }} component="th">
                    {item?.city}
                  </TableCell>
                  <TableCell sx={{ m: 1, width: 200 }} component="th">
                    {item?.street}
                  </TableCell>
                  <TableCell sx={{ m: 1, width: 150 }} component="th">
                    {item?.mobile_number}
                  </TableCell>
                  <TableCell sx={{ m: 1, width: 150 }} component="th">
                    {item?.phone_number}
                  </TableCell>
                  <TableCell sx={{ m: 1, width: 220 }} component="th">
                    {item?.email}
                  </TableCell>
                  <TableCell sx={{ m: 1, width: 180 }} component="th">
                    {item?.id}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Box>
                        <IconButton
                          aria-label="Edit"
                          size="large"
                          title="Edit supplier"
                          onClick={() => getSupplierHandler(item?.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          aria-label="Delete"
                          size="large"
                          title="Delete supplier"
                          onClick={() => handleOpen(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </TableCell>
                  <Dialog
                    open={item.id === itemId && open}
                    onClose={handleClose}
                    aria-labelledby="dialog-title"
                  >
                    <DialogTitle id="dialog-title">Delete</DialogTitle>
                    <DialogContent>
                      <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button onClick={() => handleDeleteSupplier(item.id)}>Delete</Button>
                    </DialogActions>
                  </Dialog>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                  <CustomNoRowsOverlay text="Suppliers" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <TablePagination
        labelRowsPerPage={matches ? 'Rows per page:' : ''}
        sx={{ bgcolor: 'background.paper' }}
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={(event: unknown, newPage: number) => {
          setPage(newPage);
          setOffset(newPage * limit);
        }}
        onRowsPerPageChange={(event) => {
          setLimit(parseInt(event.target.value, 10));
          setOffset(0);
          setPage(0);
        }}
      />
    </div>
  );
};
