import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  Supplier,
  SupplierDataInfo,
  SupplierInfo,
  SupplierLocationsInfo,
  SupplierWastesInfo,
  SupplierCollectionInfo
} from '../types/suppliers';

type SuppliersListData = {
  data: SupplierDataInfo[];
};

export const supplierAPI = createApi({
  reducerPath: 'supplierAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    'Suppliers',
    'SuppliersLocation',
    'SuppliersLocations',
    'SuppliersWastes',
    'SuppliersCollection'
  ],
  endpoints: (build) => ({
    fetchAllSuppliers: build.query<
      SuppliersListData,
      { limit?: number; search: string; offset?: number }
    >({
      query: ({ limit, search, offset }) => ({
        url: `/suppliers`,
        params: {
          limit: limit,
          search: search,
          offset: offset
        }
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Suppliers' as const, id })),
              { type: 'Suppliers', id: 'LIST' }
            ]
          : [{ type: 'Suppliers', id: 'LIST' }]
    }),
    createSupplier: build.mutation<SupplierInfo, SupplierInfo>({
      query: (supplier: any) => ({
        url: `/suppliers`,
        method: 'POST',
        body: supplier
      }),
      invalidatesTags: [{ type: 'Suppliers', id: 'LIST' }]
    }),
    updateSupplier: build.mutation<SupplierInfo, SupplierInfo>({
      query: (supplier) => ({
        url: `/suppliers/${supplier.supplier.id}`,
        method: 'PUT',
        body: supplier
      }),
      invalidatesTags: [{ type: 'Suppliers', id: 'LIST' }]
    }),
    fetchSupplier: build.query<Supplier, { id: string }>({
      query: ({ id }) => ({
        url: `/suppliers/${id}`
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: 'Suppliers', id: 'LIST' }]
    }),
    fetchSupplierLocation: build.query<Supplier, { id: string }>({
      query: ({ id }) => ({
        url: `/suppliers/${id}`
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: 'Suppliers', id: 'LIST' }]
    }),
    fetchSupplierWastes: build.query<Supplier, { id: string }>({
      query: ({ id }) => ({
        url: `/suppliers/${id}`
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: 'Suppliers', id: 'LIST' }]
    }),
    fetchSupplierCollection: build.query<Supplier, { id: string }>({
      query: ({ id }) => ({
        url: `/suppliers/${id}`
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: 'Suppliers', id: 'LIST' }]
    }),
    deleteSupplier: build.mutation<Supplier, { id: string }>({
      query: ({ id }) => ({
        url: `/suppliers/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Suppliers', id: 'LIST' }]
    }),
    updateSupplierLocation: build.mutation<SupplierLocationsInfo, SupplierLocationsInfo>({
      query: (supplier) => ({
        url: `/suppliers/${supplier.id}/location`,
        method: 'PUT',
        body: {
          cities_ids: supplier.cities_ids
        }
      }),
      invalidatesTags: [{ type: 'Suppliers', id: 'LIST' }]
    }),
    updateSupplierWastes: build.mutation<SupplierWastesInfo, SupplierWastesInfo>({
      query: (supplier) => ({
        url: `/suppliers/${supplier.id}/items`,
        method: 'PUT',
        body: {
          waste_items_ids: supplier.waste_items_ids
        }
      }),
      invalidatesTags: [{ type: 'Suppliers', id: 'LIST' }]
    }),
    updateSupplierCollection: build.mutation<SupplierCollectionInfo, SupplierCollectionInfo>({
      query: (supplier) => ({
        url: `/suppliers/${supplier.id}/collection`,
        method: 'PUT',
        body: {
          collection_ids: supplier.collection_ids
        }
      }),
      invalidatesTags: [{ type: 'Suppliers', id: 'LIST' }]
    })
  })
});
