// @ts-ignore
import * as yup from 'yup';

export const userValidationSchema = yup.object().shape(
  {
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().required('Email is required'),
    username: yup.string().required('User name is required'),
    phone: yup.string()
  },
  [['phone', '2fa']]
);
