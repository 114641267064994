import React from 'react';
import styled from 'styled-components/macro';
import { Paper, Typography } from '@mui/material';

import SignInComponent from '../../components/auth/SignIn';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const SignIn: React.FC = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Log in
        </Typography>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default SignIn;
