import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

interface ActionCellProps {
  onDelete?: (e: React.MouseEvent) => void;
  onEdit?: (e: React.MouseEvent) => void;
}
const CustomTableActionCell: React.FC<ActionCellProps> = ({ onDelete, onEdit }) => (
  <Box display="flex" alignItems="center" justifyContent="center">
    {onEdit && typeof onEdit === 'function' && (
      <IconButton aria-label="Edit" size="large" title="Edit" onClick={onEdit}>
        <Edit />
      </IconButton>
    )}

    {onDelete && typeof onDelete === 'function' && (
      <IconButton aria-label="Delete" size="large" title="Delete" onClick={onDelete}>
        <Delete />
      </IconButton>
    )}
  </Box>
);

export default CustomTableActionCell;
