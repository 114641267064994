import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Grid, Stack, Typography } from '@mui/material';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));
const TitleStyle = styled('span')(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.text.secondary
}));
type SupplierInfoProps = {
  company_name?: string;
  contact_name?: string;
  city?: string;
  street?: string;
  zip_code?: string;
  mobile_number?: string;
  phone_number?: string;
  email?: string;
  state?: string;
};
const SupplierInfoBlock: React.FC<SupplierInfoProps> = memo(
  ({
    company_name = '',
    contact_name = '',
    city = '',
    street = '',
    zip_code = '',
    mobile_number = '',
    phone_number = '',
    email = '',
    state = ''
  }) => {
    return (
      <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <LabelStyle>Company Name</LabelStyle>
            <Typography variant="h5" gutterBottom component="div">
              {company_name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <LabelStyle>Contact Name</LabelStyle>
            <Typography variant="h5" gutterBottom component="div">
              {contact_name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <LabelStyle>Company Address</LabelStyle>
            <Typography variant="h5" gutterBottom component="span">
              <TitleStyle>State: </TitleStyle>
              {state}
            </Typography>
            <Typography variant="h5" gutterBottom component="span">
              <TitleStyle>City: </TitleStyle>
              {city}
            </Typography>
            <Typography variant="h5" gutterBottom component="span">
              <TitleStyle>Street: </TitleStyle>
              {street}
            </Typography>
            <Typography variant="h5" gutterBottom component="span">
              <TitleStyle> Zip Code: </TitleStyle>
              {zip_code}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <LabelStyle>Contact Details</LabelStyle>
            <Typography variant="h5" gutterBottom component="span">
              <TitleStyle>Mobile Number: </TitleStyle>
              {mobile_number}
            </Typography>
            <Typography variant="h5" gutterBottom component="span">
              <TitleStyle>Phone Number: </TitleStyle>
              {phone_number}
            </Typography>
            <Typography variant="h5" gutterBottom component="span">
              <TitleStyle>Email Address: </TitleStyle>
              {email}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    );
  }
);
export default SupplierInfoBlock;
