// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO'
};

const ROOTS_AUTH = '/auth';
const ROOTS_PAGE = '/';
const ROOTS_SUPPLIER = '/supplier';

export const PATH_AUTH = {
  ROOT: ROOTS_AUTH,
  LOGIN: `${ROOTS_AUTH}/login`,
  REGISTER: `${ROOTS_AUTH}/register`,
  RESET_PASSWORD: `${ROOTS_AUTH}/reset-password`
};

export const PATH_SUPPLIER = {
  ROOT: ROOTS_PAGE,
  ADD_SUPPLIER: `${ROOTS_SUPPLIER}/add`,
  LIST_SUPPLIERS: `${ROOTS_SUPPLIER}/list`,
  EDIT_SUPPLIER: `${ROOTS_SUPPLIER}/:id/edit`,
  METHODS_SUPPLIER: `${ROOTS_SUPPLIER}/:id/methods`,
  PRICES_SUPPLIER: `${ROOTS_SUPPLIER}/:id/prices`
};

export const PATH_PAGES = {
  USERS: '/users',
  ROOT: ROOTS_PAGE,
  PAGE_404: '/404',
  PAGE_500: '/500'
};
