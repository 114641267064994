import React, { useState, useEffect, useRef } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Divider, Button, Typography, CircularProgress } from '@mui/material';
import { supplierDataAPI, supplierAPI } from '../../../../services';
import useDebounce from '../../../../hooks/useDebounce';
import { Supplier, SupplierWastesInfo } from '../../../../types/suppliers';
import ChackedTable from '../CheckedTableComponent';
import ResultSearch from '../ResultSearchComponent';
import SupplierInfoBlock from '../SupplierInfoBlock';
import useLogOut from '../../../../hooks/useLogOut';

// CONST
const DURATION = 500;
const DEFAULT_LIMIT = 3000; // FIXED LIMIT

type SupplierWastesProps = {
  supplier: Supplier;
  setStep?: any;
  refetch?: any;
};

const SupplierWastes: React.FC<SupplierWastesProps> = ({ supplier }) => {
  // HOOKS STATES
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState({
    searchWasteTypes: '',
    searchWasteItems: ''
  });
  const [backButton, setBackButton] = useState(false);
  // UPDATE SUPPLIER
  const [updateSupplierWastes, { isSuccess, error: errorUpdate, isLoading }] =
    supplierAPI.useUpdateSupplierWastesMutation();

  // FUNCTIONS HANDLERS
  const handleUpdate = (supplier: SupplierWastesInfo) => {
    updateSupplierWastes(supplier);
  };
  // FORMIK
  const formikRef = useRef();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    innerRef: formikRef,
    initialValues: {
      waste_items_ids: supplier.waste_items_ids,
      waste_types_ids: supplier.waste_types_ids
    },
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        waste_items_ids: values.waste_items_ids,
        id
      };
      handleUpdate(data);
      // resetForm();
      setSubmitting(false);
    }
  });
  const { handleSubmit, getFieldProps, setFieldValue, values } = formik;

  // DEBOUNCE HOOKS
  const debouncedValueWasteTypes = useDebounce<string>(searchResult.searchWasteTypes, DURATION);

  // FETCH WASTE TYPES & WASTE ITEMS
  const {
    data: wasteTypes = { data: [], total: null },
    error: errorWasteTypes,
    isLoading: loading
  } = supplierDataAPI.useFetchWasteTypesDataQuery(
    {
      limit: DEFAULT_LIMIT,
      search: debouncedValueWasteTypes
    }
    // { refetchOnMountOrArgChange: true }
  );
  const {
    data: wasteItems = { data: [], total: null },
    error: errorWasteItems,
    isLoading: loadingItems
  } = supplierDataAPI.useFetchWasteItemsDataQuery(
    {
      limit: DEFAULT_LIMIT,
      search: '',
      waste_types: JSON.stringify(
        values.waste_types_ids.length === 0 ? [null] : values.waste_types_ids
      )
    }
    // { refetchOnMountOrArgChange: true }
  );

  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };
  // HOOKS
  useEffect(() => {
    if (errorWasteTypes) errorHandler(errorWasteTypes);
    if (errorWasteItems) errorHandler(errorWasteItems);
    if (errorUpdate) errorHandler(errorUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorWasteTypes, errorWasteItems, errorUpdate]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });
    }
    if (isSuccess && !backButton) {
      navigate(`/supplier/${id}/edit-collection`);
    }
    if (isSuccess && backButton) {
      navigate(`/supplier/${id}/edit-location`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  const checkedItemsNames = wasteItems?.data
    ?.filter((item) => values.waste_items_ids.includes(item?.id))
    .map(({ description }) => description);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Box sx={{ display: 'grid', rowGap: 5 }}>
          <SupplierInfoBlock {...supplier?.supplier} />
          <Divider />
          <Typography variant="h4" noWrap sx={{ flexGrow: 1 }} component="div">
            Waste Types Collected
          </Typography>
          <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, md: 12 }}>
            <Grid item xs={12} md={6} lg={4}>
              <ChackedTable
                setSearchResult={setSearchResult}
                searchResult={searchResult}
                title={'Waste Type'}
                searchKey="searchWasteTypes"
                field="waste_types_ids"
                setFieldValue={setFieldValue}
                noRow="Waste Types Collected"
                loading={loading}
                childrenValue={values.waste_items_ids}
                childrenData={wasteItems?.data}
                childrenField="waste_items_ids"
                {...wasteTypes}
                {...getFieldProps('waste_types_ids')}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ChackedTable
                setSearchResult={setSearchResult}
                searchResult={searchResult}
                title={'Item'}
                isDefaultChecked
                isFilterInsideKey="description"
                searchKey="searchWasteItems"
                field="waste_items_ids"
                setFieldValue={setFieldValue}
                noRow="Waste Types Collected"
                loading={loadingItems}
                {...wasteItems}
                {...getFieldProps('waste_items_ids')}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <ResultSearch title={'Waste Collected'} data={checkedItemsNames} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={!values.waste_types_ids.length || isLoading}
              type="button"
              variant="contained"
              onClick={() => {
                handleSubmit();
                setBackButton(true);
              }}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
            <Button
              disabled={isLoading || !values.waste_types_ids.length}
              startIcon={isLoading ? <CircularProgress size="0.9rem" /> : undefined}
              type="button"
              variant="contained"
              color="primary"
              value="save"
              onClick={() => handleSubmit()}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
export default SupplierWastes;
