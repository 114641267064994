import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import PresentationLayout from './layouts/Presentation';
import DashboardLayout from './layouts/Dashboard';
// Guards
import AuthGuard from './components/guards/AuthGuard';
import GuestGuard from './components/guards/GuestGuard';
// Auth components
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ResetPassword from './pages/auth/ResetPassword';
import ForgotPassword from './pages/auth/ForgotPassword';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';
import SupplierMainPage from './pages/supplier/SupplierMainPage';
import { SupplierListPage } from './pages/supplier/SupplierListPage';
import SupplierFormPage from './pages/supplier/SupplierFormPage';
import SupplierLocation from './pages/supplier/SupplierLocationPage';
import SupplierWastes from './pages/supplier/SupplierWastesPage';
import SupplierCollection from './pages/supplier/SupplierCollectionPage';
import SupplierMethodsPage from './pages/supplier/SupplierMethodsPage';
import SupplierPricesPage from './pages/supplier/SupplierPricesPage';

import Verified from './pages/auth/Verified';
import ConfirmEmail from './pages/auth/ConfirmEmail';
import { Code } from './pages/auth/Code';
import UserList from './pages/users/UserList';
import UserPage from './pages/users/UserPage';

function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'sign-in',
          element: <SignIn />
        },
        {
          path: 'sign-up',
          element: <SignUp />
        },
        {
          path: 'confirm-email',
          element: <ConfirmEmail />
        },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: '2fa',
          element: <Verified />
        },
        {
          path: '2fa/code',
          element: <Code />
        }
      ]
    },
    {
      path: '*',
      element: <AuthLayout />,
      children: [
        {
          path: '404',
          element: <Page404 />
        },
        {
          path: '500',
          element: <Page500 />
        },
        {
          path: '*',
          element: <Navigate to="/404" />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <PresentationLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <SupplierMainPage /> },
        { path: 'supplier/list', element: <SupplierListPage /> },
        { path: 'users', element: <UserList /> },
        { path: 'users/:id', element: <UserPage /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'supplier', element: <SupplierFormPage /> },
        { path: 'supplier/add', element: <SupplierFormPage /> },
        { path: 'supplier/:id/edit', element: <SupplierFormPage /> },
        { path: 'supplier/:id/edit-location', element: <SupplierLocation /> },
        { path: 'supplier/:id/edit-wastes', element: <SupplierWastes /> },
        { path: 'supplier/:id/edit-collection', element: <SupplierCollection /> },
        { path: 'supplier/:id/methods', element: <SupplierMethodsPage /> },
        { path: 'supplier/:id/prices', element: <SupplierPricesPage /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default Router;
