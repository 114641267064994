import React from 'react';
import styled from 'styled-components/macro';
import SupplierMain from '../../components/supplier/SupplierMain';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center'
}));

const SupplierMainPage: React.FC = () => {
  return (
    <RootStyle>
      <SupplierMain />
    </RootStyle>
  );
};

export default SupplierMainPage;
