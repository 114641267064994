import React from 'react';
import styled from 'styled-components/macro';

import { Paper, Typography } from '@mui/material';

import SignUpComponent from '../../components/auth/SignUp';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const SignUp: React.FC = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Sign up
        </Typography>
        <SignUpComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default SignUp;
