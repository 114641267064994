import React, { ChangeEvent, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  FormLabel,
  FormHelperText,
  Switch,
  Typography,
  Stack,
  MenuItem
} from '@mui/material';
import { IUser } from '../../types/auth';
import { userValidationSchema } from './userValidationSchema';
import useAuth from '../../hooks/useAuth';

type EditUserProps = {
  user: IUser;
  isLoading?: boolean;
  onSave: (values: IUser) => void;
};

const EditUser: React.FC<EditUserProps> = ({ user, isLoading, onSave }) => {
  const { user: currentUser } = useAuth();
  const [twoFa, setTwoFa] = useState<'phone' | 'email'>(user?.phone ? 'phone' : 'email');
  const formik = useFormik<IUser>({
    validationSchema: userValidationSchema,
    initialValues: {
      ...user,
      phone: user?.phone || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
      username: user?.username || ''
    },
    onSubmit(values) {
      onSave(values);
    }
  });

  const changePhoneHandle = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    if (!e?.target?.value && twoFa !== 'email') {
      setTwoFa('email');
    } else if (e?.target?.value && twoFa !== 'phone') {
      setTwoFa('phone');
    }
  };

  const changeTwoFaHandle = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.value === 'email' && formik.values.phone) {
      formik.setFieldValue('phone', '');
    }
    setTwoFa(target?.value as 'email' | 'phone');
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Grid container mb={4} sx={{ bgcolor: 'background.paper' }}>
          <Grid container columnSpacing={10} rowSpacing={5} p={4}>
            <Grid item xs={12} sm={6} mb={3}>
              <TextField
                fullWidth
                name="first_name"
                label="First name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={3}>
              <TextField
                fullWidth
                name="last_name"
                label="Last name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={3}>
              <TextField
                fullWidth
                type="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={3}>
              <TextField
                fullWidth
                name="username"
                label="User name"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={3}>
              <TextField
                fullWidth
                name="phone"
                label="Phone"
                value={formik.values.phone}
                onChange={changePhoneHandle}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            {!!(currentUser?.is_quote_viewer || currentUser?.is_super_admin) && (
              <Grid item xs={6} sm={3} mb={3}>
                <FormLabel component="legend">Is quote viewer</FormLabel>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Off</Typography>
                  <Switch
                    color="primary"
                    name="is_quote_viewer"
                    checked={!!formik.values.is_quote_viewer}
                    value={!!formik.values.is_quote_viewer}
                    onChange={formik.handleChange}
                  />
                  <Typography>On</Typography>
                </Stack>
                <FormHelperText
                  error={formik.touched?.is_quote_viewer && Boolean(formik.errors.is_quote_viewer)}
                >
                  {formik.touched.is_quote_viewer && formik.errors.is_quote_viewer}
                </FormHelperText>
              </Grid>
            )}
            {!!currentUser?.is_super_admin && (
              <Grid item xs={6} sm={3} mb={3}>
                <FormLabel component="legend">Is Super Admin</FormLabel>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Off</Typography>
                  <Switch
                    color="primary"
                    name="is_super_admin"
                    checked={!!formik.values.is_super_admin}
                    value={!!formik.values.is_super_admin}
                    onChange={formik.handleChange}
                  />
                  <Typography>On</Typography>
                </Stack>
                <FormHelperText
                  error={formik.touched?.is_super_admin && Boolean(formik.errors.is_super_admin)}
                >
                  {formik.touched.is_super_admin && formik.errors.is_super_admin}
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={12} sm={6} mb={3}>
              <TextField
                fullWidth
                select
                label="2Fa Method"
                disabled={!formik.values.phone}
                value={twoFa}
                onChange={changeTwoFaHandle}
              >
                <MenuItem value="email">By Email</MenuItem>
                <MenuItem value="phone">By Phone</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            startIcon={isLoading ? <CircularProgress size="0.9rem" /> : undefined}
            type="button"
            variant="contained"
            color="primary"
            value="save"
            onClick={formik.submitForm}
          >
            Save
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default EditUser;
