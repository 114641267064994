import { useEffect, useRef, useState } from 'react';

const useObserver = () => {
  const [pageNum, setPageNum] = useState(1);
  const [lastElement, setLastElement] = useState<HTMLLIElement | null>(null);

  const observer = useRef<any>(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);
  return {
    pageNum,
    setLastElement
  };
};

export default useObserver;
