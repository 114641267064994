import React, { memo, useState, useEffect, useCallback } from 'react';
import { Toolbar, Typography, Stack, Checkbox, TableCell, Box, Popover } from '@mui/material';
import { SearchComponent } from '../SearchComponent';
import { Column, Table, AutoSizer } from 'react-virtualized';
import CustomNoRowsOverlay from '../../NoData';
import 'react-virtualized/styles.css';
import { Info } from '@mui/icons-material';

type DataRow = {
  name?: string;
  id: number;
  description?: string;
};

type SearchComponentProps = {
  setSearchResult: any;
  searchResult: any;
  data?: DataRow[];
  title: string;
  searchKey: string;
  field: string;
  total?: any | number;
  value: any;
  setFieldValue: any;
  noRow: string;
  loading: boolean;
  childrenData?: any;
  childrenValue?: any;
  childrenField?: string;
  isDefaultChecked?: boolean;
  isFilterInsideKey?: keyof DataRow;
};

const ChackedTable: React.FC<SearchComponentProps> = ({
  setSearchResult,
  searchResult,
  data = [],
  title,
  searchKey,
  field,
  value,
  setFieldValue,
  noRow,
  childrenData = [],
  childrenValue = [],
  childrenField,
  isDefaultChecked = false,
  isFilterInsideKey
}) => {
  const [isCheckedParent, setIsCheckedParent] = useState(false);
  const [uncheckedBox, setUncheckedBox] = useState<number[]>([]);
  const [isChangedBox, setIsChangedBox] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentRowIndex, setCurrentRowIndex] = useState<null | number>(null);
  const [filteredData, setFilteredData] = useState<DataRow[]>(data);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, rowIndex: number) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowIndex(rowIndex);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;

  const cellRenderer = useCallback(
    (dataKey, rowIndex, rowData) => {
      return (
        <>
          <TableCell
            component="div"
            variant="body"
            sx={{
              p: 0,
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              <Typography>
                {filteredData[rowIndex][dataKey]?.split(/(?=[A-Z])/)?.join(' ')}
              </Typography>
              {rowData.zipcodes && (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  <Typography
                    title={rowData?.zipcodes?.map((el: number) => el).join(', ')}
                    sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  >
                    {` / (${rowData?.zipcodes?.map((el: number) => el).join(', ')})`}
                  </Typography>
                </Box>
              )}
            </Box>
            {rowData.zipcodes && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ cursor: 'pointer' }} onClick={(e) => handlePopoverOpen(e, rowIndex)}>
                  <Info color="disabled" />
                </Box>
                <Box>
                  <Popover
                    id={id}
                    open={open && currentRowIndex === rowIndex}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <Box
                      sx={{
                        maxHeight: '400px',
                        maxWidth: '320px',
                        width: '100%',
                        border: '1px solid rgba(224,224,224,1)',
                        borderRadius: '5px'
                      }}
                    >
                      <Typography sx={{ p: 3 }}>
                        {`${rowData?.zipcodes?.map((el: number) => el).join(', ')}`}
                      </Typography>
                    </Box>
                  </Popover>
                </Box>
              </Box>
            )}
          </TableCell>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredData, anchorEl]
  );
  const onChangeHeaderCheckbox = useCallback(
    (e) => {
      const newSelecteds = data.map((n: any) => n?.id);
      if (e.checked) {
        setFieldValue(field, newSelecteds);
        if (isDefaultChecked) {
          setUncheckedBox([]);
        }
      } else {
        setFieldValue(field, []);
        if (isDefaultChecked) {
          setUncheckedBox(newSelecteds);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, setFieldValue, data]
  );
  const isChecked = data?.filter((item) => value?.includes(item?.id)).map((item) => item?.id);

  const onChangeRowCheckbox = useCallback(
    (e, itemId) => {
      if (isDefaultChecked) {
        if (!e.checked) {
          setUncheckedBox((prevState) => {
            if (prevState.includes(itemId)) {
              return prevState.filter((el) => el === itemId);
            } else {
              return [...prevState, itemId];
            }
          });
        }
        if (e.checked) {
          setUncheckedBox(uncheckedBox.filter((el) => el !== itemId));
        }
      }
      if (e.checked) {
        setFieldValue(field, [...value, itemId]);
        if (field !== childrenField) {
          setIsCheckedParent(false);
        }
      } else {
        setFieldValue(
          field,
          value.filter((id: number) => id !== itemId)
        );
        if (field !== childrenField) {
          setIsCheckedParent(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [childrenField, field, setFieldValue, value]
  );
  useEffect(() => {
    if (childrenField && childrenData?.length) {
      if (!value.length) {
        setFieldValue(childrenField, []);
      } else {
        const filter = childrenData.filter((item) => childrenValue?.includes(item?.id));
        const updateChildValue = filter.map((item) => item.id);
        if (isCheckedParent) {
          setFieldValue(childrenField, updateChildValue);
        } else {
          const arr = [...childrenValue, ...updateChildValue];
          setFieldValue(childrenField, [...new Set(arr)]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, childrenData]);

  // clear unchecked box if data.length === 0 but without search functionality
  useEffect(() => {
    if (
      searchResult.searchCities === '' ||
      searchResult.searchWasteItems === '' ||
      searchResult.searchContainerSizes === ''
    ) {
      if (isDefaultChecked) {
        const newSelecteds = filteredData.map((n: any) => n?.id);
        setUncheckedBox(uncheckedBox.filter((el) => newSelecteds.includes(el)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData.length]);

  // filtered data regarding unchecked boxes
  useEffect(() => {
    if (isDefaultChecked && data?.length) {
      const newSelecteds = data.map((n: any) => n?.id);
      if (!isChangedBox && value.length) {
        setIsChangedBox(true);
        setUncheckedBox(newSelecteds.filter((el) => !value.includes(el)));
      }
      const newUncheckedBox = newSelecteds.filter((x) => !uncheckedBox.includes(x));
      setFieldValue(field, newUncheckedBox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, uncheckedBox, value.length]);

  useEffect(() => {
    if (data.length) {
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    if (isFilterInsideKey && !searchResult[searchKey].length) {
      setFilteredData(data);
    } else if (isFilterInsideKey && searchResult[searchKey].length) {
      const str = searchResult[searchKey].toLowerCase().replace(/\s/g, '');
      setFilteredData(
        data?.filter((item) => {
          const field = item[isFilterInsideKey];

          if (!field) {
            return false;
          }

          if (typeof field === 'string') {
            return field.toLowerCase().replace(/\s/g, '').includes(str);
          } else {
            return field === searchResult[searchKey];
          }
        })
      );
    }
  }, [searchResult, data, searchKey, isFilterInsideKey]);

  // cleared isChangedBox state if changed step
  useEffect(() => {
    setIsChangedBox(false);
    setUncheckedBox([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
  return (
    <div style={{ height: 500 }}>
      <Stack height="100%" width="100%" sx={{ bgcolor: 'background.paper' }}>
        <Toolbar
          sx={{
            bgcolor: (theme) => theme.palette.grey.A100,
            padding: '14px',
            display: 'grid',
            gridTemplateColumns: {
              md: '155px auto',
              xs: 'auto auto'
            },
            columnGap: 3,
            rowGap: 3,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h4" component="div">
            {`Select ${title}`}
          </Typography>
          <SearchComponent
            setSearch={setSearchResult}
            searchResult={searchResult}
            searchKey={searchKey}
            width={'100%'}
          />
        </Toolbar>
        <div style={{ height: 420 }}>
          {!filteredData.length ? (
            <Box justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
              <CustomNoRowsOverlay text={noRow} />
            </Box>
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  height={height}
                  width={width}
                  headerHeight={40}
                  rowHeight={40}
                  rowStyle={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
                  rowCount={filteredData.length}
                  rowGetter={({ index }) => filteredData[index]}
                >
                  <Column
                    disableSort
                    dataKey="checkbox"
                    width={35}
                    headerRenderer={() => (
                      <Checkbox
                        indeterminate={value.length > 0 && value.length < filteredData.length}
                        checked={filteredData?.length > 0 && value.length === filteredData.length}
                        onChange={(e) => onChangeHeaderCheckbox(e.target)}
                        disabled={!!searchResult[searchKey].length}
                      />
                    )}
                    cellRenderer={({ rowIndex }) => (
                      <Checkbox
                        checked={!!isChecked.includes(filteredData[rowIndex].id)}
                        onChange={(e) => onChangeRowCheckbox(e.target, filteredData[rowIndex].id)}
                      />
                    )}
                  />

                  <Column
                    disableSort
                    dataKey={filteredData[0]?.name ? 'name' : 'description'}
                    label="Select All"
                    width={280}
                    flexGrow={1}
                    cellRenderer={({ dataKey, rowIndex, rowData }) =>
                      cellRenderer(dataKey, rowIndex, rowData)
                    }
                  />
                </Table>
              )}
            </AutoSizer>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default memo(ChackedTable);
