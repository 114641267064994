import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
// import Autocomplete from '@mui/material/Autocomplete';
// import supplierList from '../../../pages/supplier/supplierList.json';
type SearchComponentProps = {
  setSearch: any;
  searchResult?: any;
  width?: number | string;
  searchKey?: string | any;
};

export const SearchComponent: React.FC<SearchComponentProps> = ({
  setSearch,
  width = 250,
  searchResult,
  searchKey
}) => {
  const handleSearch = ({ target: { value } }) => {
    if (typeof searchResult !== 'undefined' && typeof searchKey === 'string') {
      setSearch({ ...searchResult, [searchKey]: value });
    } else {
      setSearch(value);
    }
  };

  return (
    <Stack spacing={2} sx={{ maxWidth: width, width: '100%' }}>
      <TextField
        label="Search field"
        type="search"
        variant="outlined"
        size="medium"
        onChange={handleSearch}
      />
    </Stack>
  );
};
