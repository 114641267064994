import React from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';
import { IAuthSignUpBody } from '../../types/auth';
import useAuth from '../../hooks/useAuth';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const initialValues: IAuthSignUpBody = {
  first_name: '',
  last_name: '',
  email: '',
  email_confirmation: '',
  password: '',
  password_confirmation: ''
};

const SignUp: React.FC = () => {
  const { signUp } = useAuth();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().max(200).required('First name is required'),
        last_name: Yup.string().max(200).required('Last name is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        email_confirmation: Yup.string().when('email', {
          is: (val: any) => val && val.length > 0,
          then: Yup.string().oneOf([Yup.ref('email')], 'Both emails need to be the same')
        }),
        password: Yup.string()
          .min(8, 'Must be at least 8 characters')
          .max(255)
          .required('Required')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must contain at least 8 characters, one uppercase, one lowercase letter, one number and one special case character'
          ),
        password_confirmation: Yup.string().when('password', {
          is: (val: any) => val && val.length > 0,
          then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
        })
      })}
      onSubmit={(values) => signUp(values)}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="text"
            name="first_name"
            label="First Name"
            value={values.first_name}
            error={Boolean(touched.first_name && errors.first_name)}
            fullWidth
            helperText={touched.first_name && errors.first_name}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="last_name"
            label="Last Name"
            value={values.last_name}
            error={Boolean(touched.last_name && errors.last_name)}
            fullWidth
            helperText={touched.last_name && errors.last_name}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="email"
            name="email"
            label="Email address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="email"
            name="email_confirmation"
            label="Confirm email"
            value={values.email_confirmation}
            error={Boolean(touched.email_confirmation && errors.email_confirmation)}
            fullWidth
            helperText={touched.email_confirmation && errors.email_confirmation}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="password_confirmation"
            label="Confirm password"
            value={values.password_confirmation}
            error={Boolean(touched.password_confirmation && errors.password_confirmation)}
            fullWidth
            helperText={touched.password_confirmation && errors.password_confirmation}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Create Account
          </Button>
          <Button component={Link} to="/auth/sign-in" fullWidth color="primary">
            Log in
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default SignUp;
