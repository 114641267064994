import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik, FieldArray } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styled from 'styled-components/macro';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Divider,
  IconButton as MuiIconButton,
  Button,
  CircularProgress,
  Toolbar
} from '@mui/material';
import { spacing } from '@mui/system';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { supplierAPI } from '../../../../services';
import { SupplierInfo, Supplier, Addresses } from '../../../../types/suppliers';
import useLogOut from '../../../../hooks/useLogOut';
import SupplierZipCodeAutocomplete from './SupplierZipCodeAutocomplete';

const IconButton = styled(MuiIconButton)(spacing);

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));
type CreateSupplierProps = {
  isEdit: boolean;
  supplier: Supplier;
};

const emptyItems = {
  city: '',
  street: '',
  zip_code: '',
  state: ''
};
// noinspection RegExpDuplicateCharacterInClass
// const regMatchWebsite =
//   // eslint-disable-next-line
//   /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

const CreateSupplier: React.FC<CreateSupplierProps> = ({ isEdit, supplier }) => {
  // HOOKS STATES
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);

  // CREATE/UPDATE SUPPLIER
  const [updateSupplier, { isSuccess: isSuccessUpdate, error: errorUpdate, isLoading }] =
    supplierAPI.useUpdateSupplierMutation();

  const [createSupplier, { isSuccess: isSuccessCreate, error: errorCreate, data: savedData }] =
    supplierAPI.useCreateSupplierMutation();
  // FUNCTIONS HANDLERS
  const handleCreate = (supplier: SupplierInfo) => {
    createSupplier(supplier);
  };
  const handleUpdate = (supplier: SupplierInfo) => {
    updateSupplier(supplier);
  };
  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }

    enqueueSnackbar(message, {
      variant: 'error'
    });
  };
  // HOOKS
  useEffect(() => {
    if (errorCreate) errorHandler(errorCreate);
    if (errorUpdate) errorHandler(errorUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCreate, errorUpdate]);
  useEffect(() => {
    if (isSuccessCreate) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });
      navigate(`/supplier/${id ? id : `${savedData?.supplier?.id}`}/edit-location`);
    }
    if (isSuccessUpdate) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });
      navigate(`/supplier/${id}/edit-location`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate, isSuccessUpdate]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  const SupplierSchema = Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    contact_name: Yup.string().required('Contact name is required'),
    email: Yup.string().email(),
    website: Yup.string().max(500, 'Website is too long'),
    // .matches(regMatchWebsite, 'Please enter a valid website URL (e.g https://example.com)'),
    mobile_number: Yup.string()
      .max(15, 'Mobile number is too long')
      .min(8, 'Mobile number is too short'),
    phone_number: Yup.string()
      .max(15, 'Phone number is too long')
      .min(8, 'Phone number is too short')
      .required('Phone number is required'),
    addresses: Yup.array(
      Yup.object({
        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
        street: Yup.string().required('Street is required'),
        zip_code: Yup.string()
          .required('Zip code is required')
          .matches(/^[0-9]*$/, 'Must be a number')
          .min(5, 'Must be a number with 5 characters')
          .max(5, 'Must be a number with 5 characters')
      })
    )
  });
  const formikRef = useRef();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    innerRef: formikRef,
    initialValues: {
      company_name: supplier.supplier?.company_name || '',
      contact_name: supplier.supplier?.contact_name || '',
      email: supplier.supplier?.email || '',
      website: supplier.supplier?.website || '',
      mobile_number: supplier.supplier?.mobile_number || '',
      phone_number: supplier.supplier?.phone_number || '',
      addresses: (supplier.supplier?.addresses?.length &&
        supplier.supplier?.addresses?.map((el: Addresses) => {
          return {
            city: el.city || '',
            street: el.street || '',
            zip_code: el.zip_code || '',
            state: el.state || ''
          };
        })) || [
        {
          city: supplier.supplier?.city || '',
          street: supplier.supplier?.street || '',
          zip_code: supplier.supplier?.zip_code || '',
          state: supplier.supplier?.state || ''
        }
      ]
    },
    validationSchema: SupplierSchema,

    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        supplier: {
          company_name: values.company_name || '',
          contact_name: values.contact_name || '',
          email: values.email || '',
          website: values.website || '',
          mobile_number: values.mobile_number || '',
          phone_number: values.phone_number,
          id: id || null,
          city: values.addresses[0]?.city,
          street: values.addresses[0]?.street,
          zip_code: values.addresses[0]?.zip_code,
          state: values.addresses[0]?.state || '',
          addresses: values.addresses || []
        }
      };
      isEdit ? handleUpdate(data) : handleCreate(data);
      // resetForm();
      setSubmitting(false);
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, handleBlur, values } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Toolbar
          sx={{
            bgcolor: (theme) => theme.palette.grey.A100,
            padding: '18px 14px'
          }}
        >
          <Box
            sx={{
              display: 'grid',
              minHeight: 48,
              alignItems: 'center'
            }}
          >
            <Typography variant="h4" component="div">
              Supplier Details
            </Typography>
          </Box>
        </Toolbar>
        <Box sx={{ display: 'grid', rowGap: 5 }}>
          <Grid rowSpacing={3} sx={{ padding: 4, bgcolor: 'background.paper' }}>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={6} sx={{ paddingBottom: 4 }}>
                <Stack spacing={3}>
                  <LabelStyle>Company Name</LabelStyle>
                  <TextField
                    fullWidth
                    label="Company Name"
                    {...getFieldProps('company_name')}
                    error={Boolean(touched.company_name && errors.company_name)}
                    helperText={touched.company_name && errors.company_name}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingBottom: 4 }}>
                <Stack spacing={3}>
                  <LabelStyle>Contact Name</LabelStyle>
                  <TextField
                    fullWidth
                    label="Contact Name"
                    {...getFieldProps('contact_name')}
                    error={Boolean(touched.contact_name && errors.contact_name)}
                    helperText={touched.contact_name && errors.contact_name}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <LabelStyle>Company Addresses</LabelStyle>
                  <FieldArray name="addresses">
                    {({ push, remove }) => (
                      <React.Fragment>
                        {values?.addresses?.map((elem: any, index: number) => (
                          <Box key={index} mt={3}>
                            <Box display={'flex'} alignItems={'center'} mb={index > 0 ? 3 : 0}>
                              <Typography variant="subtitle1" gutterBottom mb={0} mr={2}>
                                {index > 0 && `Address ${index}`}
                              </Typography>
                              {index !== 0 && (
                                <IconButton
                                  aria-label="Delete"
                                  mr={2}
                                  size="large"
                                  disabled={isLoading}
                                  onClick={() => remove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Box>
                            <TextField
                              fullWidth
                              label="Street"
                              {...getFieldProps(`addresses[${index}].street`)}
                              error={Boolean(
                                touched?.addresses &&
                                  errors?.addresses &&
                                  touched?.addresses[index]?.street &&
                                  errors?.addresses[index]?.street
                              )}
                              helperText={
                                touched?.addresses &&
                                errors?.addresses &&
                                touched?.addresses[index]?.street &&
                                errors?.addresses[index]?.street
                              }
                            />
                            <TextField
                              fullWidth
                              sx={{ mt: 3 }}
                              label="City"
                              {...getFieldProps(`addresses[${index}].city`)}
                              error={Boolean(
                                touched?.addresses &&
                                  errors?.addresses &&
                                  touched?.addresses[index]?.city &&
                                  errors?.addresses[index]?.city
                              )}
                              helperText={
                                touched?.addresses &&
                                errors?.addresses &&
                                touched?.addresses[index]?.city &&
                                errors?.addresses[index]?.city
                              }
                            />
                            <TextField
                              fullWidth
                              sx={{ mt: 3 }}
                              label="State"
                              {...getFieldProps(`addresses[${index}].state`)}
                              error={Boolean(
                                touched?.addresses &&
                                  errors?.addresses &&
                                  touched?.addresses[index]?.state &&
                                  errors?.addresses[index]?.state
                              )}
                              helperText={
                                touched?.addresses &&
                                errors?.addresses &&
                                touched?.addresses[index]?.state &&
                                errors?.addresses[index]?.state
                              }
                            />
                            <SupplierZipCodeAutocomplete
                              index={index}
                              values={values}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              getFieldProps={getFieldProps}
                              errorHandler={errorHandler}
                            />
                          </Box>
                        ))}

                        <Grid item>
                          {typeof errors.addresses === 'string' ? (
                            <Typography color="error">{errors.addresses}</Typography>
                          ) : null}
                        </Grid>

                        <Box width={250} my={4}>
                          <Button
                            disabled={isLoading}
                            variant="text"
                            color="primary"
                            onClick={() => push(emptyItems)}
                          >
                            <AddIcon />
                            Add Another Address...
                          </Button>
                        </Box>
                      </React.Fragment>
                    )}
                  </FieldArray>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <LabelStyle>Contact Details</LabelStyle>
                  <TextField
                    {...getFieldProps('mobile_number')}
                    label="Mobile Number"
                    value={values.mobile_number}
                    name="mobile_number"
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '' || +e.target.value || e.target.value === '+')
                        setFieldValue('mobile_number', e.target.value);
                    }}
                    error={Boolean(touched.mobile_number && errors.mobile_number)}
                    helperText={touched.mobile_number && errors.mobile_number}
                  />
                  <TextField
                    label="Phone Number"
                    {...getFieldProps('phone_number')}
                    value={values.phone_number}
                    name="phone_number"
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '' || +e.target.value || e.target.value === '+')
                        setFieldValue('phone_number', e.target.value);
                    }}
                    error={Boolean(touched.phone_number && errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                  />
                  <TextField
                    fullWidth
                    label="Email Address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    label="Website"
                    type="url"
                    placeholder="example.com"
                    {...getFieldProps('website')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('website', e.target.value.replace(/\s/g, ''));
                    }}
                    error={Boolean(touched.website && errors.website)}
                    helperText={touched.website && errors.website}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size="0.9rem" /> : undefined}
              type="button"
              variant="contained"
              color="primary"
              value="save"
              onClick={() => handleSubmit()}
            >
              {!isEdit ? 'Save supplier' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
export default CreateSupplier;
