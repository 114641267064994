import React from 'react';
import { List, ListItem, Toolbar, Typography, Box, Stack } from '@mui/material';

type SearchComponentProps = {
  data?: string[];
  title: string;
};

const ResultSearch: React.FC<SearchComponentProps> = ({ data = [], title }) => {
  return (
    <div style={{ height: 500 }}>
      <Stack height="100%" width="100%" sx={{ bgcolor: 'background.paper' }}>
        <Toolbar
          sx={{
            bgcolor: (theme) => theme.palette.grey.A100,
            padding: '18px 14px'
          }}
        >
          <Box
            sx={{
              display: 'grid',
              minHeight: 52,
              alignItems: 'center'
            }}
          >
            <Typography variant="h4" component="div">
              {`Selected ${title}`}
            </Typography>
          </Box>
        </Toolbar>
        <List
          sx={{
            width: '100%',
            height: 500,
            bgcolor: 'background.paper',
            overflow: 'auto',
            border: '1px solid rgba(224, 224, 224, 1)'
          }}
        >
          {data?.map((item, idx) => (
            <ListItem key={`${item}-${idx}`}>{item}</ListItem>
          ))}
        </List>
      </Stack>
    </div>
  );
};

export default ResultSearch;
