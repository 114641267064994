export const getErrorMsg = (error: any): string => {
  let message: string | any = 'Something went wrong!';

  if (error?.data?.errors) {
    if (typeof error?.data?.errors === 'string') {
      message = error?.data?.errors;
    }

    if (Array.isArray(error?.data?.errors)) {
      message = error?.data?.errors?.map((item: any) => item?.message || item)[0];
    }

    if (typeof error?.data?.errors === 'object') {
      message = Object.values(error?.data?.errors)?.map((item: any) => item?.message || item)[0];
    }
  }

  if (error?.originalStatus === 401) {
    message = 'You are not authorized!';
  }

  return message;
};
