import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { Outlet } from 'react-router-dom';
import { Hidden, CssBaseline, Paper as MuiPaper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import createTheme from '../theme';
import GlobalStyle from '../components/GlobalStyle';
import Navbar from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import Footer from '../components/Footer';
import { THEMES } from '../constants';

const drawerWidth = 260;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: calc(100% - ${drawerWidth}px);
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard: React.FC = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <MuiThemeProvider theme={createTheme(THEMES.LIGHT)}>
      <ThemeProvider theme={createTheme(THEMES.LIGHT)}>
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <Drawer>
            <Hidden lgUp implementation="js">
              <Sidebar
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden mdDown implementation="css">
              <Sidebar PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </Drawer>
          <AppContent>
            <Navbar onDrawerToggle={handleDrawerToggle} />
            <MainContent p={isLgUp ? 12 : 5} sx={{ zIndex: 1 }}>
              {children}
              <Outlet />
            </MainContent>
            <Footer />
          </AppContent>
        </Root>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Dashboard;
