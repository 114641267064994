import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link as MuiLink,
  Typography
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomTable from '../../components/custom-table/CustomTable';
import { usePagination } from '../../hooks/usePagination';
import { usersApi } from '../../services';
import { RootStyle } from '../../utils/RootStyle';
import { AuthUser } from '../../types/auth';
import { TableRowData } from '../../types/custom-table';
import { PATH_SUPPLIER } from '../../constants';

const HEADERS = [
  { header: 'User name', key: 'userNameKey' },
  { header: 'First Name', key: 'firstNameKey' },
  { header: 'Last Name', key: 'lastNameKey' },
  { header: 'Email', key: 'userEmailKey' },
  { header: 'Phone', key: 'userPhoneKey' },
  { header: 'Is Super admin', key: 'isSuperAdminKey' },
  { header: 'Is quote viewer', key: 'isQuotViewerKey' },
  { header: '2FA Method', key: 'user2FaTypeKey' },
  { header: '', key: 'userActionsTypeKey' }
];

const getUserRowData = (row: AuthUser): TableRowData[] => [
  { value: row?.username || '-', key: `${row?.id}-username` },
  { value: row?.first_name || '-', key: `${row?.id}-firstName` },
  { value: row?.last_name || '-', key: `${row?.id}-lastName` },
  { value: row?.email || '-', key: `${row?.id}-email` },
  { value: row?.phone || '-', key: `${row?.id}-phone` },
  { value: row?.is_super_admin ? <Check /> : <Close />, key: `${row?.id}-isSuperAdmin` },
  { value: row?.is_quote_viewer ? <Check /> : <Close />, key: `${row?.id}-isQuotViewer` },
  { value: row?.phone ? 'By phone' : 'By Email', key: `${row?.id}-2fa` }
];

const UserList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { onChangePage, onChangeRowsPerPage, pagination } = usePagination();
  const [selectedId, setSelectedId] = useState<number | string>();

  const [deleteUser, { error: deleteErr, isSuccess }] = usersApi.useDeleteUserMutation();
  const { data, isLoading, error } = usersApi.useFetchUsersQuery({
    limit: pagination.rowsPerPage,
    offset: pagination.page * pagination.rowsPerPage,
    search: ''
  });

  const closeDialogHandle = () => setSelectedId(undefined);

  const onDelete = (row: AuthUser) => setSelectedId(row?.id);

  const editHandle = (row: AuthUser) => navigate(`${row?.id}`);

  const deleteHandle = () => {
    selectedId && deleteUser(selectedId);
    closeDialogHandle();
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('User was deleted', { variant: 'success' });
    }
  }, [isSuccess, enqueueSnackbar]);

  return (
    <RootStyle maxWidth="lg">
      <Grid container mb={10}>
        <Grid item xs={12} mt={10} mb={10}>
          <Typography variant="h3">User Management</Typography>
          <Breadcrumbs>
            <MuiLink component={Link} to={PATH_SUPPLIER.ROOT}>
              Phoenix Waste Collection
            </MuiLink>
            <Typography>Users</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            data={data?.data}
            headers={HEADERS}
            loading={isLoading}
            error={error || deleteErr}
            getRowData={getUserRowData}
            onDelete={onDelete}
            onEdit={editHandle}
            pagination={pagination}
            count={data?.count}
            onRowsPerPageChange={onChangeRowsPerPage}
            onPageChange={onChangePage}
          />
        </Grid>
      </Grid>

      <Dialog open={!!selectedId} onClose={closeDialogHandle} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeDialogHandle}>
            Cancel
          </Button>
          <Button onClick={deleteHandle}>Delete</Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
};

export default UserList;
