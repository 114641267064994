import React, { useEffect } from 'react';
import { RootStyle } from '../../utils/RootStyle';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Link as MuiLink,
  Toolbar,
  Typography
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PATH_PAGES, PATH_SUPPLIER } from '../../constants';
import EditUser from './EditUser';
import { useSnackbar } from 'notistack';
import { IUser } from '../../types/auth';
import { usersApi } from '../../services';
import { getErrorMsg } from '../../utils/getErrorMsg';

const UserPage: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { data, error, isLoading } = usersApi.useFetchUserQuery({ id: `${id}` }, { skip: !id });
  const [saveUser, { error: saveError, isSuccess }] = usersApi.useUpdateUserMutation();

  const saveHandle = (values: IUser) => {
    if (!values?.id) {
      return enqueueSnackbar('Incorrect user id', { variant: 'error' });
    }
    saveUser(values);
  };

  useEffect(() => {
    const err = error || saveError;
    if (err) {
      enqueueSnackbar(getErrorMsg(error), { variant: 'error' });
    }
  }, [error, saveError, enqueueSnackbar]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('User was updated', { variant: 'success' });
      navigate(PATH_PAGES.USERS);
    }
  }, [isSuccess, enqueueSnackbar, navigate]);

  return (
    <RootStyle maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h3" gutterBottom>
              Edit User Details
            </Typography>
            <Breadcrumbs>
              <MuiLink component={Link} to={PATH_SUPPLIER.ROOT}>
                Phoenix Waste Collection
              </MuiLink>
              <MuiLink component={Link} to={PATH_PAGES.USERS}>
                Users
              </MuiLink>
              <Typography>Edit user</Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Toolbar
            sx={{
              bgcolor: (theme) => theme.palette.grey.A100,
              padding: '18px 14px'
            }}
          >
            <Box sx={{ display: 'grid', minHeight: 48, alignItems: 'center' }}>
              <Typography variant="h4" component="div">
                User Details
              </Typography>
            </Box>
          </Toolbar>
          {isLoading && (
            <Box display="flex" justifyContent="center" p={10} bgcolor="background.paper">
              <CircularProgress />
            </Box>
          )}
          {!data && !!error && (
            <Box display="flex" justifyContent="center" p={10} bgcolor="background.paper">
              <Typography variant="h5">Something went wrong</Typography>
            </Box>
          )}
          {data && <EditUser user={data} isLoading={isLoading} onSave={saveHandle} />}
        </Grid>
      </Grid>
    </RootStyle>
  );
};

export default UserPage;
