import React, { ChangeEvent, MouseEvent, useEffect } from 'react';
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import styled from 'styled-components/macro';
import { tableCellClasses } from '@mui/material/TableCell';
import { TableRowData } from '../../types/custom-table';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import CustomTableActionCell from './CustomTableActionCell';
import { useSnackbar } from 'notistack';
import { getErrorMsg } from '../../utils/getErrorMsg';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100
  }
}));

const Loader: React.FC<{ colSpan?: number }> = ({ colSpan = 6 }) => (
  <TableRow>
    <TableCell colSpan={colSpan} align="center">
      <CircularProgress color="secondary" />
    </TableCell>
  </TableRow>
);

type CustomTableProps = {
  data?: any[];
  getRowData: (row: any) => TableRowData[] | string[][] | number[][];
  headers?: { header: string; key: any }[] | string[];
  loading?: boolean;
  onEdit?: (row: any) => void;
  onDelete?: (row: any) => void;
  rowClick?: (row: any) => void;
  pagination?: { page: number; rowsPerPage: number; search?: string };
  count?: number;
  rowsPerPageOptions?: number[];
  onPageChange?: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error: any;
};

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  getRowData,
  headers,
  loading,
  onDelete,
  onEdit,
  rowClick,
  pagination,
  count,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
  error
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onRowClickHandle = (row: any) => (e: React.MouseEvent<HTMLTableRowElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    rowClick && rowClick(row);
  };

  const editHandle = (row: any) => (e: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    onEdit && onEdit(row);
  };

  const deleteHandle = (row: any) => (e: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    onDelete && onDelete(row);
  };

  const getHeaders = (): any[] => {
    if (headers) {
      return headers;
    }

    if (data && data[0]) {
      return getRowData(data[0]);
    }

    return [];
  };

  const getCell = (cell: any, key?: keyof TableRowData): string | number | null => {
    if (typeof cell === 'string' || typeof cell === 'number' || !key) {
      return cell;
    }

    if (typeof cell === 'object') {
      return cell[key];
    }

    return null;
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorMsg(error), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  return (
    <>
      <TableContainer component={Paper} sx={{ height: 650 }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {getHeaders()?.map((v, i) => (
                <StyledTableCell key={`header-${getCell(v, 'key')}`} align={!i ? 'left' : 'center'}>
                  {getCell(v, 'header')}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ marginBottom: 20 }}>
            {loading && <Loader colSpan={headers?.length || (data && data[0]?.length)} />}

            {!loading && !data && (
              <TableRow>
                <TableCell colSpan={headers?.length} align="center">
                  <Typography p={10} variant="h4">
                    Have no data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {data?.map((row, idx) => (
              <TableRow key={row?.id || `row-${idx}`} onClick={rowClick && onRowClickHandle(row)}>
                {getRowData(row)?.map((cell, i) => (
                  <TableCell key={`cell-${getCell(cell, 'key')}`} align={!i ? 'left' : 'center'}>
                    {getCell(cell, 'value')}
                  </TableCell>
                ))}

                {(onEdit || onDelete) && (
                  <TableCell align="center">
                    <CustomTableActionCell
                      onEdit={onEdit && editHandle(row)}
                      onDelete={onDelete && deleteHandle(row)}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data && pagination && onPageChange && onRowsPerPageChange && (
        <>
          <Divider />
          <TablePagination
            sx={{ bgcolor: 'background.paper' }}
            labelRowsPerPage="Rows per page:"
            rowsPerPageOptions={rowsPerPageOptions || [10, 20, 50]}
            component="div"
            count={count || data?.length || 0}
            rowsPerPage={pagination?.rowsPerPage}
            page={pagination?.page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </>
      )}
    </>
  );
};

export default CustomTable;
