import React from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Button, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const ForgotPassword: React.FC = () => {
  const { forgotPassword } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: false
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
      })}
      onSubmit={(values) => {
        forgotPassword(values.email);
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // disabled={isSubmitting}
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
