import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthUser } from '../types/auth';

interface ISearchParams {
  limit: number;
  offset?: number;
  search?: string;
}

interface IUsersResponseData {
  data: AuthUser[];
  count: number;
  hasMore: boolean;
}

// useFetchUsersQuery,
// useFetchUserQuery,
// useUpdateUserMutation,
// useDeleteUserMutation
export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['users'],
  endpoints: (build) => ({
    fetchUsers: build.query<IUsersResponseData, ISearchParams>({
      query: ({ limit, offset, search }) => ({
        url: '/list/users',
        params: { limit, offset, search }
      }),
      keepUnusedDataFor: 0,
      providesTags: ['users']
    }),
    fetchUser: build.query<AuthUser, { id: string | number }>({
      query: ({ id }) => `/users/${id}`,
      keepUnusedDataFor: 0,
      providesTags: ['users']
    }),
    updateUser: build.mutation<AuthUser, Partial<AuthUser>>({
      query: (user) => ({
        url: `/users/${user?.id}`,
        method: 'PUT',
        body: user
      })
    }),
    deleteUser: build.mutation<AuthUser, string | number>({
      query: (id) => ({ url: `/users/${id}`, method: 'DELETE' }),
      invalidatesTags: ['users']
    })
  })
});
