import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// material
import { spacing, SpacingProps } from '@mui/system';

import styled from 'styled-components/macro';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------
import {
  AppBar as MuiAppBar,
  Button as MuiButton,
  Container,
  Grid,
  Link as MuiLink,
  Toolbar
} from '@mui/material';
import { PATH_SUPPLIER } from '../constants';
import { Logo } from '../assets';

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Brand = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  color: ${(props) => props.theme.header.color};
`;

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  height: 65px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 56px;
  }
`;

const StyledLink = styled(MuiLink)`
  display: flex;
  justify-content: space-between;
  width: 110px;
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  color: ${(props) => props.theme.header.color};
  border: none;
  background-color: transparent;
  &:hover {
    opacity: 0.8;
    border: none;
    background-color: transparent;
  }
`;

export default function Header() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate('/auth/sign-in');
  };

  return (
    <AppBar position="relative" elevation={0}>
      <Toolbar>
        <Container maxWidth={false}>
          <Grid container alignItems="center">
            <StyledLink underline="none" component={Link} to={PATH_SUPPLIER.ROOT}>
              <img src={Logo} alt="Logo" />
              <Brand>Phoenix</Brand>
            </StyledLink>
            <Grid item xs />
            <Grid item>
              <StyledButton ml={2} color="primary" variant="contained" onClick={handleSignOut}>
                Sign out
              </StyledButton>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
