import React from 'react';
import styled from 'styled-components/macro';
import Box from '@mui/material/Box';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  minHeight: '100%',
  flexDirection: 'column'
}));

type NoRowsProps = {
  text: string;
};

const CustomNoRowsOverlay: React.FC<NoRowsProps> = ({ text }) => {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No {text}</Box>
    </StyledGridOverlay>
  );
};

export default CustomNoRowsOverlay;
