import React, { useMemo, useState } from 'react';
import { Checkbox, TableCell, TableRow, TextField } from '@mui/material';
import { CollectionMethodContainer } from '../../../../types/suppliersData';
import InputAdornment from '@mui/material/InputAdornment';

type CollectionTableRowProps = {
  item: CollectionMethodContainer;
  index: number;
  getFieldProps: any;
  handleChangePrice: any;
  handleChecked: any;
  id: string | undefined | number;
};

export const TableRowComponent: React.FC<CollectionTableRowProps> = ({
  item,
  index,
  getFieldProps,
  handleChangePrice,
  handleChecked,
  id
}) => {
  const [priceValue, setPriceValue] = useState<string | number>(0);
  return useMemo(() => {
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th">{item?.collection_info?.waste_type_name}</TableCell>
        <TableCell component="th">{item?.collection_info?.container_name}</TableCell>
        <TableCell>
          <TextField
            {...getFieldProps(`containers[${index}].price_per_collection`)}
            label="Price"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangePrice(e, index);
            }}
            id={`${item?.id}-text-field`}
            value={!!item?.price_per_collection ? item?.price_per_collection : priceValue}
            onFocus={() => {
              setPriceValue('');
            }}
            onBlur={() => {
              setPriceValue(0);
            }}
            InputProps={{
              startAdornment: (item?.price_per_collection > 0 || priceValue > 0) && (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            {...getFieldProps(`containers[${index}].free_of_charge`)}
            checked={!!item?.free_of_charge}
            onChange={(e) => {
              handleChecked(e, index);
            }}
            color="primary"
            inputProps={{
              'aria-labelledby': `${id}`
            }}
          />
        </TableCell>
        <TableCell>
          <TextField
            {...getFieldProps(`containers[${index}].pricing_details`)}
            label="Notes"
            id={`${item?.id}-text-field`}
            // value={!!item?.price_per_collection ? item?.price_per_collection : priceValue}
          />
        </TableCell>
      </TableRow>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFieldProps, index, priceValue]);
};
