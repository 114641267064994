import { Container } from '@mui/material';
import styled from 'styled-components/macro';

export const RootStyle = styled(Container)({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center'
});
