import React from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, TextField as MuiTextField, Typography, Stack } from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import FormHelperText from '@mui/material/FormHelperText';

type VerifiedProps = {
  handleNext: any;
};
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Verified: React.FC<VerifiedProps> = ({ handleNext }) => {
  const { verified } = useAuth();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        email: '',
        phone: '',
        submit: false
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255),
        phone: Yup.string().min(11, 'Phone number is not valid')
      })}
      validateOnChange
      onSubmit={async (values) => {
        if (values.email === '' && values.phone === '') {
          enqueueSnackbar('Fill in at least one field', {
            variant: 'warning'
          });
        } else {
          await verified(values.email, values.phone, token, handleNext);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Typography sx={{ color: 'text.secondary' }}>or</Typography>
            <PhoneInput
              country="us"
              value={values.phone}
              onChange={(phone) => setFieldValue('phone', phone)}
              enableSearch={true}
              inputStyle={{
                width: '100%',
                borderColor: touched.phone && errors.phone ? 'red' : ''
              }}
            />
            {touched.phone && errors.phone && (
              <FormHelperText style={{ color: '#d32f2f', marginTop: '3px', marginLeft: '14px' }}>
                {errors.phone}
              </FormHelperText>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Send Code
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default Verified;
