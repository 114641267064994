import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { supplierAPI, supplierDataAPI, usersApi } from '../services';
import detailsSlice from './slices/supplierDetail';

const rootReducer = combineReducers({
  [supplierAPI.reducerPath]: supplierAPI.reducer,
  [supplierDataAPI.reducerPath]: supplierDataAPI.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  supplierDetail: detailsSlice
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        supplierAPI.middleware,
        supplierDataAPI.middleware,
        usersApi.middleware
      ])
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
