import React, { useState, useEffect, useRef } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Grid, Divider, Button, Typography, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { supplierDataAPI, supplierAPI } from '../../../../services';
import useDebounce from '../../../../hooks/useDebounce';
import { Supplier, SupplierCollectionInfo } from '../../../../types/suppliers';
import ChackedTable from '../CheckedTableComponent';
import ResultSearch from '../ResultSearchComponent';
import SupplierInfoBlock from '../SupplierInfoBlock';
import useLogOut from '../../../../hooks/useLogOut';

// CONST
const DURATION = 500;
const DEFAULT_LIMIT = 3000; // FIXED LIMIT

type SupplierContainersProps = {
  supplier: Supplier;
  refetch?: any;
};

const SupplierContainers: React.FC<SupplierContainersProps> = ({ supplier }) => {
  // HOOKS STATES
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const [searchResult, setSearchResult] = useState({
    searchContainerTypes: '',
    searchContainerSizes: ''
  });
  const [backButton, setBackButton] = useState(false);
  // UPDATE SUPPLIER
  const [updateSupplierCollection, { isSuccess, error: errorUpdate, isLoading }] =
    supplierAPI.useUpdateSupplierCollectionMutation();

  // FUNCTIONS HANDLERS
  const handleUpdate = (supplier: SupplierCollectionInfo) => {
    updateSupplierCollection(supplier);
  };
  // FORMIK
  const formikRef = useRef();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    innerRef: formikRef,
    initialValues: {
      containers_types_ids: supplier.collection_types_ids,
      containers_sizes_ids: supplier.collection_ids
    },
    onSubmit: async (values, { setSubmitting }) => {
      const data = {
        collection_ids: values.containers_sizes_ids,
        id
      };
      handleUpdate(data);
      // resetForm();
      setSubmitting(false);
    }
  });
  const { handleSubmit, getFieldProps, setFieldValue, values } = formik;

  // DEBOUNCE HOOKS
  const debouncedValueContainerTypes = useDebounce<string>(
    searchResult.searchContainerTypes,
    DURATION
  );
  // FETCH CONTAINER TYPES & CONTAINER SIZES
  const {
    data: containersTypes = { data: [], total: null },
    error: errorContainersTypes,
    isLoading: loading
  } = supplierDataAPI.useFetchContainerTypesDataQuery(
    {
      limit: DEFAULT_LIMIT,
      search: debouncedValueContainerTypes
    }
    // { refetchOnMountOrArgChange: true }
  );
  const {
    data: containersSizes = { data: [], total: null },
    error: errorContainersSizes,
    isLoading: loadingItems
  } = supplierDataAPI.useFetchContainerSizesDataQuery(
    {
      limit: DEFAULT_LIMIT,
      search: '',
      containers_types: JSON.stringify(
        values.containers_types_ids.length === 0 ? [null] : values.containers_types_ids
      )
    }
    // { refetchOnMountOrArgChange: true }
  );

  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };
  // HOOKS
  useEffect(() => {
    if (errorContainersTypes) errorHandler(errorContainersTypes);
    if (errorContainersSizes) errorHandler(errorContainersSizes);
    if (errorUpdate) errorHandler(errorUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorContainersTypes, errorContainersSizes, errorUpdate]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });
    }
    if (isSuccess && !backButton) {
      navigate(`/supplier/${id}/methods`);
    }
    if (isSuccess && backButton) {
      navigate(`/supplier/${id}/edit-wastes`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const checkedSizesNames = containersSizes?.data
    ?.filter((item) => values.containers_sizes_ids.includes(item?.id))
    .map(({ name }) => name);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Box sx={{ display: 'grid', rowGap: 5 }}>
          <SupplierInfoBlock {...supplier?.supplier} />
          <Divider />
          <Typography variant="h4" noWrap sx={{ flexGrow: 1 }} component="div">
            Vehicle/Container Sizes Used
          </Typography>
          <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, md: 12 }}>
            <Grid item xs={12} md={6} lg={4}>
              <ChackedTable
                setSearchResult={setSearchResult}
                searchResult={searchResult}
                title={'Service Model'}
                searchKey="searchContainerTypes"
                field="containers_types_ids"
                setFieldValue={setFieldValue}
                noRow="Vehicle/Container Sizes Used"
                loading={loading}
                childrenValue={values.containers_sizes_ids}
                childrenData={containersSizes?.data}
                childrenField="containers_sizes_ids"
                {...containersTypes}
                {...getFieldProps('containers_types_ids')}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ChackedTable
                setSearchResult={setSearchResult}
                searchResult={searchResult}
                title={'Sizes'}
                isDefaultChecked
                isFilterInsideKey="name"
                searchKey="searchContainerSizes"
                field="containers_sizes_ids"
                setFieldValue={setFieldValue}
                noRow="Vehicle/Container Sizes Used"
                loading={loadingItems}
                {...containersSizes}
                {...getFieldProps('containers_sizes_ids')}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <ResultSearch title={'Vehicle/Containers Used'} data={checkedSizesNames} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={!values.containers_types_ids.length || isLoading}
              type="button"
              variant="contained"
              onClick={() => {
                handleSubmit();
                setBackButton(true);
              }}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
            <Button
              disabled={isLoading || !values.containers_types_ids.length}
              startIcon={isLoading ? <CircularProgress size="0.9rem" /> : undefined}
              type="button"
              variant="contained"
              color="primary"
              value="save"
              onClick={() => handleSubmit()}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
export default SupplierContainers;
